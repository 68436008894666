import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, IconButton, Collapse, Card, Box } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const ProjectSensors = ({ orderedData, setOrderedData, disableButton, setDisableButton, projectView }) => {
    const state = useSelector(st => st)
    const { getProjectById: projectById } = state
    const [showParameter, setShowParameter] = useState({
        water_quantity: false,
        water_quality: false,
        energy: false,
        equipment_status: false,
        pressure: false,
        level: false,
        air_quality: false,
        temperature: false
    })

    const moveItem = (group, index, direction) => {
        const items = [...orderedData[group]];
        const newIndex = index + direction;

        if (newIndex < 0 || newIndex >= items.length) {
            return;
        }

        const itemToMove = items[newIndex];
        items[newIndex] = items[index];
        items[index] = itemToMove;

        items[newIndex].position = newIndex + 1;
        items[index].position = index + 1;
        if (disableButton) {
            setDisableButton(false)
        }
        setOrderedData({
            ...orderedData,
            [group]: items
        });
    };

    if (projectById?.data?.projectData?.projectMeter?.length === 0) {
        return <div className='w3-text-gray w3-margin'>No sensors available</div>
    }

    return (
        <div>
            {Object.entries(orderedData)?.map(([group, items]) => {
                if (items?.length === 0) {
                    return ''
                }
                return (
                    <div key={group} className='w3-margin'>
                        <Card
                            variant="outlined"
                            onClick={() => setShowParameter(prevState => ({
                                ...prevState,
                                [group]: !showParameter[group],
                            }))}
                            style={{cursor: 'pointer'}}
                        >
                            <Box display="flex" justifyContent="space-between">
                                <div 
                                    className="w3-margin"
                                    style={{ textTransform: 'capitalize', fontSize: 16, marginBottom: 4 }}
                                >
                                    {group.replace('_', ' ')}
                                </div>
                                <IconButton size="small">
                                    {showParameter[group] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </IconButton>
                            </Box>
                        </Card>
                        <Collapse in={showParameter[group]}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 550 }} aria-label="simple table" className='project-sensor-table'>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Meter Id</TableCell>
                                            <TableCell>Meter Name</TableCell>
                                            <TableCell>Position</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {items?.map((item, index) => (
                                            <TableRow
                                                key={`${item.id}-${index}`}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell>
                                                    {item?.meter?.meterid}
                                                </TableCell>
                                                <TableCell>{item?.meter?.metername}</TableCell>
                                                <TableCell>
                                                    <IconButton disabled={(index === 0 ? true : false) || projectView } onClick={() => moveItem(group, index, -1)}>
                                                        <ArrowUpwardIcon />
                                                    </IconButton>
                                                    <IconButton disabled={(index === items?.length - 1 ? true : false) || projectView } onClick={() => moveItem(group, index, 1)}>
                                                        <ArrowDownwardIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Collapse>
                    </div>
                )
            })}
        </div>
    );
};

export default ProjectSensors;