import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getClientAssetById } from '../../Redux/actions';
import { Button, Card, Chip, CircularProgress, Grid, IconButton, Paper, Stack, Tooltip, Typography } from '@mui/material';
import MaintenanceLog from './MaintenanceLog';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { calculateDueDays } from '../../utils';

export default function ClientAssetDetails() {
    const { client_id, id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [assetShowLoader, setAssetShowLoader] = useState(true)
    const [logsShowLoader, setLogsShowLoader] = useState(true)
    const [assetData, setAssetData] = useState({});

    const clientAssetQueryParams = localStorage.getItem("clientAssetQueryParams");

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        setAssetShowLoader(true)
        setLogsShowLoader(true)
        dispatch(getClientAssetById(client_id, id)).then((res) => {
            if (res && res.status === 200) {
                setAssetShowLoader(false)
                const respData = res?.data?.assetData
                if (!respData) {
                    return;
                }
                setAssetData(respData)
            }
        })
    }

    const properties = [
        { label: 'Asset Name:', value: assetData?.name || '--' },
        { label: 'Component ID:', value: assetData?.component_id || '--' },
        { label: 'Category:', value: assetData?.category || '--' },
        { label: 'Serial Number:', value: assetData?.serial_number || '--' },
        { label: 'Rating:', value: assetData?.rating ? `${assetData?.rating} kW` : '--' },
        { label: 'Unit Price:', value: assetData?.unit_price ? `₹ ${assetData?.unit_price}` : '--' },
        { label: 'Asset Cost:', value: assetData?.cost ? `₹ ${assetData?.cost}` : '--' },
        {
            label: 'Purchase Date:',
            value: assetData?.purchase_date
              ? new Date(assetData.purchase_date).toLocaleString(undefined, {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })
              : '--'
        },
        {
            label: 'Installation Date:',
            value: assetData?.installation_date
              ? new Date(assetData.installation_date).toLocaleString(undefined, {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })
              : '--'
        },
        {
            label: 'Operation Start Date:',
            value: assetData?.operation_start_date
              ? new Date(assetData.operation_start_date).toLocaleString(undefined, {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })
              : '--'
        },
        {
            label: 'Operation End Date:',
            value: assetData?.operation_end_date
              ? new Date(assetData.operation_end_date).toLocaleString(undefined, {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })
              : '--'
        },
        {
            label: 'End Of Life Date:',
            value: assetData?.end_of_life_date
              ? new Date(assetData.end_of_life_date).toLocaleString(undefined, {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })
              : '--'
        },
        { label: 'Facility:', value: assetData?.facility?.facility_name ? assetData?.facility?.facility_name : '--' },
        {
            label: 'Associated Meter:',
            value: (
              <span>
                {assetData?.assetMeter?.meter?.metername ? assetData.assetMeter.meter.metername : '--'}
                {assetData?.assetMeter?.meter?.metername && (
                  <Chip
                    label={assetData?.assetMeter?.meter?.meterid}
                    size="small"
                    color="primary"
                    style={{ marginLeft: '8px' }}
                    onClick={() => { 
                        navigate(`/client/${client_id}/meters/trending-analysis/${assetData?.assetMeter?.meter?.mid}`)
                    }}
                  />
                )}
              </span>
            ),
        },
        {
            label: 'Geo Tagging:',
            value: (assetData?.geo_proof_lat && assetData?.geo_proof_lon) ? (
              <span>
                <Typography component="span" style={{ color: '#1976d2' }}>Lat:</Typography>
                {assetData.geo_proof_lat}, 
                <Typography component="span" style={{ color: '#1976d2', marginLeft: '4px' }}>Lon:</Typography>
                {assetData.geo_proof_lon}
              </span>
            ) : '--',
        },
        { label: 'Particulars:', value: assetData?.particulars ? assetData?.particulars : '--' },   
    ];

    let ClientAssetDetails 

    if( assetShowLoader ) {
        ClientAssetDetails = (
            <Paper sx={{ padding: 2 }} >
                <Grid container item className='w3-padding' justifyContent='center'>
                    <CircularProgress />
                </Grid>
            </Paper>
        )
    } else if(assetData && Object.keys(assetData).length !== 0) {
        const nextmaintenanceSchedule = calculateDueDays(assetData?.operation_start_date, assetData?.componentSchedule, assetData?.assetMaintenance, assetData?.operation_end_date )
        ClientAssetDetails = (
            <Paper 
                sx={{
                    wordWrap: 'break-word',
                    padding: {
                    xs: 0,
                    sm: 2, 
                    md: 2  
                    }
                }}
                >
                {properties.map((property, index) => (
                    <div key={index}>
                        <Grid container item className='w3-padding'>
                            <Grid item xs={12} sm={6} md={6}>
                                <Typography sx={{ fontWeight: 'bold' }}>{property.label}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                {property.value}
                            </Grid>
                        </Grid>
                    </div>
                ))}
                <Grid className='w3-padding' >
                    <div style={{ fontWeight: 'bold', color: 'red' }}>{nextmaintenanceSchedule?.overdueDays ? `Maintenance Overdue by ${nextmaintenanceSchedule?.overdueDays} Days` : (nextmaintenanceSchedule?.dueDays !== null ? `Next maintenance in ${nextmaintenanceSchedule?.dueDays} days` : '--')}</div>
                    <div style={{ fontSize: '12px'}}>{nextmaintenanceSchedule?.actualMaintenanceDate ? `Actual Maintenance (${nextmaintenanceSchedule?.actualMaintenanceDate})` : null}</div>
                </Grid>
            </Paper>
        )
    } else {
        ClientAssetDetails = (
            <Paper sx={{ padding: 2 }} >
                <Grid container item className='w3-padding' justifyContent='center'>
                    <h5 className='w3-text-grey'>
                        No Data Found
                    </h5>
                </Grid>
            </Paper>
        )    
    }
    
    return (
        <Grid 
            container 
            sx={{
                padding: {
                xs: '12px 8px', 
                sm: '12px 8px', 
                md: '12px 24px' 
                }
            }}
        >
            <Grid container item xs={12}>
                <Grid item xs={5} justifyContent='flex-start'>
                    <IconButton
                        onClick={() => clientAssetQueryParams ? navigate(`/client/${client_id}/client-assets?${clientAssetQueryParams?.toString()}`) : navigate(`/client/${client_id}/client-assets`)}
                        color="inherit">
                        <ArrowBackIosIcon fontSize='small'/>
                    </IconButton>
                </Grid>
                <Grid item xs={7} justifyContent='flex-start'>
                    <h5 style={{ fontWeight: 'bold' }}>ASSET DETAILS</h5>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ margin: '0px 24px 26px 24px' }}>
               {ClientAssetDetails}
            </Grid>
            <Grid container item xs={12} justifyContent='center'>
                <h5 style={{ fontWeight: 'bold' }}>MAINTENANCE LOG DETAILS</h5>
            </Grid>
            <Grid item xs={12}>
               <MaintenanceLog />
            </Grid>
        </Grid>
    )
}