import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ManageClientAsset from './ManageClientAsset';
import MaintenanceLog from './MaintenanceLog';
import { Can } from '../../Context/Can';
import TabPanel from '@mui/lab/TabPanel/TabPanel';
import { Box, IconButton, Tab } from '@mui/material';
import TabContext from '@mui/lab/TabContext/TabContext';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import TabList from '@mui/lab/TabList';

export default function ClientAssetsCommon() {

    const { client_id, tabId, id } = useParams();
    const tabNames = ['maintenance', 'edit'];
    const navigate = useNavigate();
    const [value, setValue] = useState('1');

    const clientAssetQueryParams = localStorage.getItem("clientAssetQueryParams"); 

    useEffect(() => {
        setValue(tabId)
    }, [tabId]);

    const handleTabSwitch = (event, newValue) => {
        setValue(newValue);
        navigate(`/client/${client_id}/client-assets/${id}/${newValue}`)
    }; 

    let found = false;
    for (const tabName of tabNames) {
        if (window.location.pathname.includes(tabName)) {
            found = true;
            break;
        }
    }

    if (!found) {
        navigate('/page-not-found');
    }

    return <>
        <div className='w3-padding-large'>
            <div >
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={value}>
                        <Box id="digital-twin-tab-container" sx={{ borderBottom: 1, borderColor: 'divider',  display: 'flex', alignItems: 'center' }}>
                            <IconButton 
                                onClick={() => clientAssetQueryParams ?  navigate(`/client/${client_id}/client-assets?${clientAssetQueryParams.toString()}`) : navigate(`/client/${client_id}/client-assets/`)}
                                color="inherit">
                                <ArrowBackIosIcon fontSize='small'/>
                            </IconButton>
                            <TabList onChange={handleTabSwitch} aria-label="lab API tabs example">
                                <Tab label="Maintenance Log" value="maintenance" />
                                <Tab label="Edit Asset" value="edit" />
                                
                            </TabList>
                        </Box>
                        <TabPanel style={{ padding: '0px' }} value="maintenance"><Can I="view" a="MaintenanceLog"><MaintenanceLog /></Can></TabPanel>
                        <TabPanel style={{ padding: '0px' }} value="edit"><Can I="view" a="EditAsset"><ManageClientAsset /></Can></TabPanel>
                    </TabContext>
                </Box>
            </div>
        </div>
    </>
}