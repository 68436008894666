import { Chip, Grid, Paper, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';

export default function ViewMeter({ form, selectedMeterTags, selectedProjectIds, installation_date, parameterGrp, constantValueFlag, waterType }) {

    const formattedInstallationDte = installation_date
        ? dayjs(installation_date).format('DD/MM/YYYY')
        : '--'; 

    const properties = [
        { label: 'Meter Name:', value: form?.meterName || '--' },
        { label: 'Meter ID:', value: (form?.meter_id ? 
            (<Chip color='primary' label={form?.meter_id} size="small"/>) : ('--'))},
        {
            label: 'Meter Tags:',
            value: selectedMeterTags?.length > 0 ? 
              selectedMeterTags.map((item) => (
                <Chip variant="outlined" style={{marginRight:'10px', marginBottom: '5px'}} key={item?.key} label={item?.text} color="primary" size="small" />
              )) 
              : '--'
        },
        { label: 'Meter Address:', value: form?.meterAddress || '--' },
        { label: 'Projects:', value: selectedProjectIds?.length > 0 ?
            selectedProjectIds?.map((item) => (
                <Chip style={{marginRight:'10px', marginBottom: '5px'}} key={item?.pid} label={item?.projname} size="small" />
            ))
            : '--'
         },
        { label: 'Date of Installation:', value: formattedInstallationDte},
        { label: 'Parameter Group', value: parameterGrp?.label || '--'},
        { label: 'Hardcoded Meter Name:', value: form?.hardcodedMtrName || '--'},
        { label: 'Min:', value: form?.min || '--'},
        { label: 'Min Remarks:', value: form?.minRemarks || '--'},
        { label: 'Max:', value: form?.max || '--'},
        { label: 'Max Remarks:', value: form?.maxRemarks || '--'},
        { label: 'Alert Priority:', value: (form?.alertPriority ? 
            (<Chip color='secondary' label={form?.alertPriority} size="small"/>) : ('--'))},
        { label: 'Meter Status:', value: (form?.status ? 
            (<Chip label={form?.status} size="small"/>) : ('--'))},
        {
            label: 'Hide Chart:', value: (
                <>
                    
                    {form?.hideChart ? (
                        <Typography 
                            sx={{ paddingLeft: '2px', fontSize: '1.1rem', color: 'green' }} 
                            component="span"
                        >
                            ✅ 
                        </Typography>
                    ) : (
                        <Typography 
                            sx={{ paddingLeft: '2px', fontSize: '0.9rem', color: 'red' }} 
                            component="span"
                        >
                            ❌
                        </Typography>
                    )}
                </>
            )
        },
        { label: 'Percentage Limit:', value: `${form?.percLimit}%` || '--'},
    ];

    if (parameterGrp?.value === 'level') {
        const index = properties.findIndex(prop => prop.label === 'Percentage Limit:');
        if ( index !== -1 ) {
            properties.splice( index + 1, 0, 
                { label: 'Tank Area:', value: form?.tankArea || '--' },
                { label: 'Tank Height:', value: form?.tankHeight || '--' },
                {
                    label: 'Constant:', value: (
                        <>
                            
                            {constantValueFlag ? (
                                <Typography 
                                    sx={{ paddingLeft: '2px', fontSize: '1.1rem', color: 'green' }} 
                                    component="span"
                                >
                                    ✅ 
                                </Typography>
                            ) : (
                                <Typography 
                                    sx={{ paddingLeft: '2px', fontSize: '0.9rem', color: 'red' }} 
                                    component="span"
                                >
                                    ❌
                                </Typography>
                            )}
                        </>
                    )
                },
                { label: 'Constant Value:', value: form?.constantValue || '--' }
            )
        }
    }

    if (parameterGrp?.value === 'water_quantity') {
        const index = properties.findIndex(prop => prop.label === 'Max Remarks:');
        if (index !== -1) {
            properties.splice(index + 1, 0, { label: 'Water Type:', value: waterType?.label || '--' });
        }
    }

    const sensorDetails = (
        <Paper 
                sx={{
                    wordWrap: 'break-word',
                    padding: {
                    xs: 0,
                    sm: 2, 
                    md: 2  
                    }
                }}
                >
            {properties.map((property, index) => (
                <div key={index}>
                    <Grid container item className='w3-padding'>
                        <Grid item xs={12} sm={6} md={6}>
                            <Typography sx={{ fontWeight: 'bold' }}>{property.label}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            {property.value}
                        </Grid>
                    </Grid>
                </div>
            ))}
        </Paper>
    )
    return (
        <Grid 
            container 
            sx={{
                padding: {
                xs: '12px 8px', 
                sm: '12px 8px', 
                md: '12px 24px' 
                }
            }}
        >
            <Grid container item xs={12}>
                <Grid item xs={12} display="flex" justifyContent="center">
                    <h5 style={{ fontWeight: 'bold', marginBottom: '10px' }}>SENSOR DETAILS</h5>
                </Grid>
            </Grid>
            <Grid item xs={12}>
               {sensorDetails}
            </Grid>
        </Grid>
    )
    
}