import { Button, CircularProgress, Grid, IconButton, Pagination, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Tooltip } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { addZohoProducts, getAllTickets, getTicketsByAssignee } from '../../Redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import SearchBox from '../Common/SearchBox';
import { updateParams } from '../../utils';
import FilterListIcon from '@mui/icons-material/FilterList';
import Filter from '../Common/Filter';
import { green } from '@mui/material/colors';
import { ChevronRight } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import useBackActionDetection from '../../utils/UseBackActionDetection';
import SyncIcon from '@mui/icons-material/Sync';
import Loader from '../Common/Loader';

export default function Tickets() {

    const { facilityId: facility_id, client_id: client_id } = useParams();
    const dispatch = useDispatch();
    const [queryParams, setQueryParams] = useSearchParams();
    const existingParams = Object.fromEntries([...queryParams])
    const [ticketsData, setTicketsData] = useState([]);
    const [showLoader, setShowLoader] = useState(true)
    const [totalCount, setTotalCount] = useState(0);
    const [totalPages, setTotalPages] = useState(null)
    const [showFilterDialog, setShowFilterDialog] = useState(false);
    const [isSearchOrFilterApplied, setIsSearchOrFilterApplied] = useState(false);
    const [tabSwitch, setTabSwitch] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 450);
    const [addingZohoPrdctLoader, setAddingZohoPrdctLoader] = useState(false);
    const [currentPage, setCurrentPage] = useState(null);
    const path = window.location.pathname;
    const ticketsTabs = ['all-tickets', 'my-tickets']
    const urlParams = new URLSearchParams(window.location.search);
    const tabValue = urlParams.get('tab');
    let navigate = useNavigate();
    const location = useLocation();

    const state = useSelector(st => st);
    const { currentUser: { uniqueId } } = state

    const isBackAction = useBackActionDetection();

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (existingParams?.page || existingParams?.tab) {
            return
        }
        if (!queryParams.get('tab')) {
            updateQueryParams({ tab: 'all-tickets' });
        }
        if(!existingParams.page){
            updateQueryParams({ tab: 'all-tickets',page:1 });
        }
        fetchData();
    }, [ queryParams ]);

    useEffect(() => {
        setIsSearchOrFilterApplied(false);
        fetchData();
    }, [queryParams, facility_id])

    const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 450); // Adjust according to screen size
    };

    useEffect(() => {
        // Listen to window resize events
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const fetchData = () => {
        setShowLoader(true)
        
        if(queryParams.get('tab') === 'all-tickets'){
            const params = {
                ...existingParams,
                title: existingParams?.search,
                page: existingParams?.page,
            }
            if (facility_id) {
                params.facility_id = facility_id;
            }
            if ( client_id ) {
                params.client_id = client_id;
            }
            dispatch(getAllTickets(params)).then((res) => {
                if (res && res.status === 200) {
                    const respData = res?.data?.data
                    setTicketsData(respData)
                    setShowLoader(false)
                    setTotalPages(res?.data?.totalPages)
                    setCurrentPage(res?.data?.currentPage)
                    setTotalCount(res?.data?.totalItems);
                }
            })
            return
        } else if (queryParams.get('tab') === 'my-tickets') {
            const params = {
                ...existingParams,
                title: existingParams?.search,
                page: isSearchOrFilterApplied ? 1 : existingParams?.page,
            }
            if (facility_id) {
                params.facility_id = facility_id;
            }
            dispatch(getTicketsByAssignee(params)).then((res) => {
                if (res && res.status === 200) {
                    const respData = res?.data?.data
                    setTicketsData(respData)
                    setShowLoader(false)
                    setTotalPages(res?.data?.totalPages)
                    setCurrentPage(res?.data?.currentPage)
                    setTotalCount(res?.data?.totalItems);
                }

            })
        }
    }

    const updateQueryParams = (params) => {
        const searchParams = updateParams(existingParams, params)
        navigate({ search: searchParams.toString() }, { replace: true });
    }

    const handleChangeTab = (event, newValue) => {
        setQueryParams({ tab: newValue, page: 1 })
        // queryParams.set('tab', newValue);
        // queryParams.set('page', 1);
        // navigate({ search: queryParams.toString() }, { replace: false });
        setTabSwitch(true);
    };
    

    const handleSearch = (value) => {
        updateQueryParams({ search: value, tab: queryParams.get('tab'), page: 1  });
        setIsSearchOrFilterApplied(true);
    }

    const handlePageChange = (event, newPage) => {
        updateQueryParams({ page: newPage });
    };

    const handleTabSwitch = () => {
        setTabSwitch(false);
    };

    const handleZohoProductSync = () => {
        setAddingZohoPrdctLoader(true);
        dispatch(addZohoProducts())
            .then((res) => {
                setAddingZohoPrdctLoader(false);
            })
            .catch((error) => {
                setAddingZohoPrdctLoader(false);
                console.error("Zoho Product Sync Failed:", error);
            });
    };

    let ticketList = []
    const itemsPerPage = 20;

    if (  urlParams.has('tab') && !ticketsTabs.includes(tabValue) ) {
        ticketList = (
            <TableRow>
                <TableCell colSpan={12} align="center">
                    <h5 className='w3-text-grey'>
                        No Data Found
                    </h5>
                </TableCell>
            </TableRow>
        )
    }
    else if( showLoader) {
        ticketList = (
            <TableRow>
                <TableCell colSpan={12} align="center">
                    <CircularProgress />
                </TableCell>
            </TableRow>
        )
    } else if ( ticketsData?.length > 0 ) {
        ticketList = (
            ticketsData.map((item,index) => (
            <TableRow key={item?.id}
                className="tickets-table-row" sx={{ verticalAlign: 'top' }}
                onClick={() => { 
                    //Navigation
                    if ( client_id && !facility_id ) {
                        navigate(`/client/${client_id}/client-tickets/${item?.id}`)
                    } else if ( facility_id ) {
                        navigate(`/client/${client_id}/digitalisation/${facility_id}/tickets/details/${item?.id}`)
                    } else {
                        navigate(`/tickets/details/${item?.id}`)
                    }

                    //Storing Queryparams
                    if ( client_id && !facility_id ) {
                        localStorage.setItem('clientTicketsQueryParams', queryParams)
                    } else if ( facility_id ) {
                        localStorage.setItem('digiTicketsQueryParams', queryParams)
                    } else {
                        localStorage.setItem('ticketsQueryParams', queryParams);
                    }
                }}
            >
                {isSmallScreen && (<TableCell align="right">
                    <Tooltip title="Details">
                        <ChevronRight className="cursor-pointer"
                            style={{ color: green[500] }}
                        />
                    </Tooltip>
                </TableCell>)}
                <TableCell>{(currentPage - 1) * itemsPerPage + index + 1}</TableCell>
                <TableCell>{item?.title}</TableCell>
                <TableCell>{item?.description}</TableCell>
                { !client_id && (<TableCell>{item?.client?.name}</TableCell>) }
                { !facility_id && (<TableCell>{item?.facility?.facility_name}</TableCell>) }
                <TableCell>{item?.id}</TableCell>
                <TableCell>{item?.assignee ?  item?.assignee : '--'}</TableCell>
                <TableCell>{item?.status}</TableCell>
                {/* <TableCell>{item?.scope ? item?.scope : '--'}</TableCell> */}
                {!isSmallScreen && (<TableCell align="right">
                    <Tooltip title="Details">
                        <ChevronRight className="cursor-pointer"
                            style={{ color: green[500] }}
                        />
                    </Tooltip>
                </TableCell>)}
            </TableRow>))
        )
    } else {
        ticketList = (
            <TableRow>
                <TableCell colSpan={12} align="center">
                    <h5 className='w3-text-grey'>
                        No Data Found
                    </h5>
                </TableCell>
            </TableRow>
        )
    }

    

    return (
        <Grid 
            sx={{
                padding: {
                xs: '12px 8px', 
                sm: '12px 8px', 
                md: '12px 24px' 
                }
            }}
        >
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={6}>
                    { !client_id && (<Tabs value={queryParams.get('tab')} onChange={handleChangeTab}>
                        <Tab label="All Tickets" value="all-tickets" />
                        <Tab label="My Tickets" value="my-tickets" />
                    </Tabs>)}
                </Grid>
                <Grid 
                    item 
                    xs={12} 
                    md={6} 
                    container
                    sx={{
                    justifyContent: {
                        xs: 'flex-start', 
                        sm: 'flex-end', 
                        md: 'flex-end'  
                    }
                    }}
                >
                    { uniqueId?.includes('greenvironment.in') && (<IconButton 
                        onClick={handleZohoProductSync} 
                        style={{marginRight: '10px', color: '#33a433'}}
                        title="Sync Zoho Products Data">
                        <SyncIcon />
                    </IconButton>)}
                    <h6 style={{ paddingRight: '5px', fontSize: '15px' }}>Search By: </h6>
                    <SearchBox
                        value={queryParams.get('search') || ''}
                        placeholder={client_id ? 'ID / Title / Desc' : 'ID / Title / Desc / Client'}
                        search={handleSearch}
                        isBrowserBackAction={isBackAction}
                    />
                    <IconButton onClick={() => setShowFilterDialog(true)} title="Apply Filter">
                        <FilterListIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <Filter
                filterArea="Tickets"
                showFilterDialog={showFilterDialog}
                setShowFilterDialog={setShowFilterDialog}
                updateQueryParams={updateQueryParams}
                queryParams={queryParams}
                setIsSearchOrFilterApplied={setIsSearchOrFilterApplied}
                clearFilters={tabSwitch}
                handleTabSwitch={handleTabSwitch}
                checkForUrlFacility={ facility_id ? true : false}
                checkForMyTickets={ queryParams.get('tab') === 'my-tickets' ? true : false } 
                isBrowserBackAction={isBackAction}
            />
            <TableContainer component={Paper} style={{ marginTop: '16px' }}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {isSmallScreen && (<TableCell align="left" className='client-table-head'></TableCell>)}
                            <TableCell align="left" className='client-table-head'>Sl.No.</TableCell>
                            <TableCell align="left" className='client-table-head'>Title</TableCell>
                            <TableCell align="left" className='client-table-head'>Description</TableCell>
                            { !client_id && (<TableCell align="left" className='client-table-head'>Client</TableCell>)}
                            { !facility_id && (<TableCell align="left" className='client-table-head'>Facility</TableCell>)}
                            <TableCell align="left" className='client-table-head'>Ticket ID</TableCell>
                            <TableCell align="left" className='client-table-head'>Assignee</TableCell>
                            <TableCell align="left" className='client-table-head'>Status</TableCell>
                            {/* <TableCell align="left" className='client-table-head'>Scope</TableCell> */}
                            {!isSmallScreen && (<TableCell align="left" className='client-table-head'></TableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {ticketList}
                    </TableBody>
                </Table>
            </TableContainer>
            {(ticketsData && ticketsData?.length > 0 && !showLoader ) && (
                <Grid container item xs={12} style={{ marginBottom: '30px', marginTop: '12px' }}>
                    {/* Items Per Page Information */}
                    <Grid item xs={12} display="flex" justifyContent="end" style={{ marginBottom: '10px' }}>
                        <span style={{ fontFamily: '"Helvetica Neue"', fontSize: '13px', color: '#3d3d3d' }}>
                            {Math.min((parseInt(existingParams.page ? existingParams.page : 1 ) - 1) * itemsPerPage + 1, totalCount)}-
                            {Math.min(parseInt(existingParams.page ? existingParams.page : 1 ) * itemsPerPage, totalCount)} of {totalCount}
                        </span>
                    </Grid>
                    {/* Pagination Controls */}
                    <Grid item xs={12} display="flex" justifyContent="center">
                        <Pagination className='pagination'
                            page={parseInt(existingParams?.page) || 1}
                            count={totalPages}
                            variant="outlined"
                            shape="rounded"
                            color="primary"
                            onChange={handlePageChange}
                            size={isSmallScreen ? 'small' : 'medium'}
                            siblingCount={isSmallScreen ? 0 : 1}
                        />
                    </Grid>
                </Grid>
            )}
            {addingZohoPrdctLoader && <Loader open={addingZohoPrdctLoader}/>}
        </Grid>
    )
}
