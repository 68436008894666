import { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getAllClients } from '../Redux/actions';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { CircularProgress, Tooltip, IconButton, Chip } from '@mui/material';
import { green } from '@mui/material/colors';
import { ChevronRight } from '@mui/icons-material';
import { deleteClient } from '../Redux/actions';
import ConfirmDialog from './Common/ConfirmDialog';
import AlertDialog from './Common/AlertDialog';
import SearchBox from './Common/SearchBox';
import { updateParams } from '../utils';
import Pagination from '@mui/material/Pagination';
import { Can } from '../Context/Can';
import Filter from './Common/Filter';
import useBackActionDetection from '../utils/UseBackActionDetection';

export default function ClientListing() {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const location = useLocation();
    const [queryParams, setQueryParams] = useSearchParams();
    const existingParams = Object.fromEntries([...queryParams])
    const [filterOptions, setFilterOptions] = useState(existingParams);
    const isBackAction = useBackActionDetection();


    const [clientData, setClientData] = useState(null);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [alertMessage, setAlertMessage] = useState();
    const [msgType, setMsgType] = useState("success");
    const [showFilterDialog, setShowFilterDialog] = useState(false);
    const [regions, setRegions] = useState(null);
    const [totalPages, setTotalPages] = useState(null)
    const [showLoader, setShowLoader] = useState(true);
    const [isSearchOrFilterApplied, setIsSearchOrFilterApplied] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(null);



    const goToRoutes = (routeApi, replace = true) => {
        navigate(routeApi, { replace });
    }

    const fetchData = () => {
        setShowLoader(true)
        const params = {
            ...existingParams,
            title: existingParams?.search,
            page: isSearchOrFilterApplied ? 1 : existingParams?.page,

        }
        dispatch(getAllClients(params)).then((res) => {
            if (res && res.status === 200) {
                const respData = res.data
                if (!respData) {
                    setShowLoader(false);
                    return;
                }
                setClientData(respData.data)
                setShowLoader(false)
                setTotalPages(respData.totalPages)
                setTotalCount(respData.totalItems);
                setCurrentPage(respData.currentPage)
                const uniqueRegions = new Set();
                respData.allData?.forEach((item) => uniqueRegions.add(item.region));
                const uniqueRegionsArray = Array.from(uniqueRegions).filter((region) => region !== null);;
                setRegions(uniqueRegionsArray)
            }
        })
    }
    useEffect(() => {
        if (existingParams?.page) {
            return
        }
        updateQueryParams({ page: 1 })
    }, [queryParams])

    useEffect(() => {
        setIsSearchOrFilterApplied(false);
        fetchData();
    }, [queryParams])

    const deleteClientById = () => {
        dispatch(deleteClient(selectedItem)).then((resp) => {
            if (resp && resp.status === 200) {
                const result = resp.data;
                if (!result.success) {
                    setAlertMessage(result.message);
                    setMsgType("fail");
                    return;
                }
                setShowConfirmDialog(false)
                setSelectedItem(null);
                setAlertMessage(result.message);
                setMsgType("success");
                fetchData();
            } else {
                setAlertMessage('Deletion failed');
                setMsgType("fail");
            }
        })
    }

    const triggerDelete = (client) => {
        setShowConfirmDialog(true);
        setSelectedItem(client);
    }

    const handleDialogClose = () => {
        setAlertMessage(null);
        navigate('/client-listing');
    };

    const updateQueryParams = (params) => {
        const searchParams = updateParams(existingParams, params)
        navigate({ search: searchParams.toString() }, { replace: true });
    }

    const handleSearch = (value) => {
        updateQueryParams({ search: value, page: 1 });
        setIsSearchOrFilterApplied(true);
    }

    const handlePageChange = (event, newPage) => {
        updateQueryParams({ page: newPage });
    };

    const itemsPerPage = 20;

    return (
        <Grid>
            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[100]
                            : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
            >

                <Grid container>
                    <Grid item container spacing={8} paddingY={2} paddingX={3}>
                        <Grid item  md={6}>
                            <div className='w3-hide'>
                                <Can do="manage" on="CreateClient">
                                    <Button variant="contained"
                                        onClick={() => { goToRoutes('/client/create') }}
                                    >Create New Client</Button>
                                </Can>
                            </div>
                        </Grid>
                        <Grid item  md={6} container justifyContent="flex-end" direction="row">
                            <Grid item container md={12} direction="row">
                                <Grid item md={12} container justifyContent="flex-end">
                                    <h6 style={{ paddingRight: '5px', fontSize: '15px'}}>Search By: </h6>
                                    <SearchBox
                                        value={queryParams.get('search') || ''}
                                        placeholder='Client Name / Domain / Facility / PID'
                                        search={handleSearch}
                                        isBrowserBackAction={isBackAction}
                                    />
                                </Grid>
                                {/* <Grid item container md={1} justifyContent="flex-end">
                                    <IconButton onClick={() => setShowFilterDialog(true)} title="Apply Filter">
                                        <FilterListIcon />
                                    </IconButton>
                                </Grid> */}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Filter
                    filterArea="Clients"
                    regionsOption={regions}
                    showFilterDialog={showFilterDialog}
                    setShowFilterDialog={setShowFilterDialog}
                    updateQueryParams={updateQueryParams}
                    queryParams={queryParams}
                    setIsSearchOrFilterApplied={setIsSearchOrFilterApplied}
                />

                <div className='w3-padding-large'>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className='client-table-head'>Sl.No.</TableCell>
                                    <TableCell className='client-table-head'>Client Name</TableCell>
                                    <TableCell className='client-table-head'>Domain</TableCell> 
                                    <TableCell className='client-table-head'>Contact</TableCell>
                                    <TableCell className='client-table-head'>SPOC</TableCell>
                                    <TableCell className='client-table-head'>Address</TableCell>
                                    <TableCell className='client-table-head'></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody style={{ wordWrap: "break-word"}}>
                                {showLoader ? (
                                    <TableRow>
                                        <TableCell colSpan={6} align="center">
                                            <CircularProgress />
                                        </TableCell>
                                    </TableRow>
                                ) : clientData?.length > 0 ? clientData.map((item,index) => (
                                    <TableRow key={item?.id}
                                        className="client-table-row" sx={{ verticalAlign: 'top' }}
                                        onClick={() => { goToRoutes(`/client/${item?.id}/assets`, false) }}
                                    >
                                        <TableCell>{(currentPage - 1) * itemsPerPage + index + 1}</TableCell>
                                        <TableCell>{item?.name} <br /> <br />
                                            {item.ex_data?.zoho?.outstanding > 0 && <Chip color="warning" size="small" label={` ₹${item.ex_data?.zoho?.outstanding}`} />} <br /> <br />
                                            {(item.ex_data?.openDeskTickets === 0 || item.ex_data?.openDeskTickets > 0) && <Chip color="primary" size="small" label={` Open Desk Tickets: ${item.ex_data?.openDeskTickets}`} />}
                                        </TableCell>
                                        <TableCell>{item?.domain}</TableCell>
                                        <TableCell>
                                            {item?.primary_contact}
                                            <div style={{ fontSize: '10px', marginTop: '6px', color: 'grey' }}>
                                                {item.ex_data?.zoho?.contactList?.map(item => (
                                                    <div key={`client-name-${item?.name}`}>{item?.name} {item?.email ? ` - ${item?.email}` : ''}</div>
                                                ))}
                                            </div>
                                        </TableCell>
                                        <TableCell>{item?.clientSpoc?.name}</TableCell>
                                        <TableCell>{item?.address}</TableCell>
                                        <TableCell align='right' style={{ width: '5%' }}>
                                            <Tooltip title="Details">
                                                <IconButton aria-label="edit" >
                                                    <ChevronRight className="cursor-pointer"
                                                        style={{ color: green[500] }}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                )) : (<TableRow>
                                    <TableCell colSpan={6} align="center">
                                        <h5 className='w3-text-grey'>
                                            No Data Found
                                        </h5>
                                    </TableCell>
                                </TableRow>)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                {(clientData && (clientData.length > 0) && !showLoader ) && (
                    <Grid container item xs={12} style={{ display: 'flex', justifyContent: 'center', paddingRight: '24px', marginBottom: '30px' }}>
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <span style={{ fontFamily: '"Helvetica Neue"', fontSize: '13px', color: '#3d3d3d' }}>
                                {Math.min((parseInt(existingParams.page) - 1) * itemsPerPage + 1, totalCount)}-
                                {Math.min(parseInt(existingParams.page) * itemsPerPage, totalCount)} of {totalCount}
                            </span>
                        </Grid>
                        <Pagination
                            page={parseInt(existingParams?.page) || 1}
                            count={totalPages}
                            variant="outlined"
                            shape="rounded"
                            color="primary"
                            onChange={handlePageChange}
                        />
                    </Grid>
                )}
            </Box>
            {alertMessage ? (
                <AlertDialog
                    handleClose={() => setAlertMessage(null)}
                    message={alertMessage}
                    type={msgType}
                    handleDialogClose={handleDialogClose}
                />) : null}
            {showConfirmDialog
                ? (
                    <ConfirmDialog
                        handleCancel={() => {
                            setSelectedItem({})
                            setShowConfirmDialog(false)
                        }}
                        handleOk={deleteClientById}
                        message={'Are you sure to delete this client?'}
                    />
                ) : null}
        </Grid>
    )
}