import { Checkbox, Chip, Grid, Paper, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';

export default function ViewProject({ form, startDate, facilityName, startTime, endTime, reportsChecked }) {

    const formattedStartDate = startDate
        ? dayjs(startDate).format('DD/MM/YYYY')
        : '--';   

    const properties = [
        { label: 'Project Name:', value: form?.projectName || '--' },
        { label: 'Project Short Name:', value: form?.projectShortName || '--' },
        { label: 'Map Coordinates:', value: form?.map_url},
        { label: 'Facility:', value: facilityName || '--' },
        { label: 'Type of Plant:', value: (form?.plantType ? 
            (<Chip label={form?.plantType} color="secondary" size="small"/>) : ('--'))},
        { label: 'Type of Project:', value: (form?.projectType ? 
            (<Chip label={form?.projectType} color="primary" size="small"/>) : ('--'))},
        { label: 'STP Type:', value: form?.stpType || '--' },
        { label: 'Plant Capacity:', value: (form?.capacity ? 
            (<Chip label={form?.capacity} size="small"/>) : ('--'))},
        { label: 'Opex Cost:', value: form?.opexCost || '--'},
        { label: 'Start / Installation Date:', value: formattedStartDate},
        { label: 'Daily Report Emails:', value: form?.dailyReportsEmail || '--'},
        {
            label: (
                <>
                    Daily Reports
                    {reportsChecked ? (
                        <Typography 
                            sx={{ paddingLeft: '8px', fontSize: '1rem', color: 'green' }} 
                            component="span"
                        >
                            ✅ 
                        </Typography>
                    ) : (
                        <Typography 
                            sx={{ paddingLeft: '8px', fontSize: '0.8rem', color: 'red' }} 
                            component="span"
                        >
                            ❌
                        </Typography>
                    )}
                </>
            ),
            value: (startTime && endTime) 
                ? `Start Time: ${dayjs(startTime).format('hh:mm A')} - End Time: ${dayjs(endTime).format('hh:mm A')}` 
                : '--',
        },
    ];

    const projectDetails = (
        <Paper 
                sx={{
                    wordWrap: 'break-word',
                    padding: {
                    xs: 0,
                    sm: 2, 
                    md: 2  
                    }
                }}
                >
            {properties.map((property, index) => (
                <div key={index}>
                    <Grid container item className='w3-padding'>
                        <Grid item xs={12} sm={6} md={6}>
                            <Typography sx={{ fontWeight: 'bold' }}>{property.label}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            {property.value}
                        </Grid>
                    </Grid>
                </div>
            ))}
        </Paper>
    )



    return (
        <Grid 
            container 
            sx={{
                padding: {
                xs: '12px 8px', 
                sm: '12px 8px', 
                md: '12px 24px' 
                }
            }}
        >
            <Grid container item xs={12}>
                <Grid item xs={12} display="flex" justifyContent="center">
                    <h5 style={{ fontWeight: 'bold', marginBottom: '10px' }}>PROJECT DETAILS</h5>
                </Grid>
            </Grid>
            <Grid item xs={12}>
               {projectDetails}
            </Grid>
        </Grid>
    )
}