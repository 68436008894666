import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { getAllReports } from '../../Redux/actions';
import { Box, Tooltip, TableCell, TableRow, IconButton, CircularProgress, Pagination } from '@mui/material';
import { Edit } from '@mui/icons-material';
import SearchBox from '../Common/SearchBox'
import FilterListIcon from '@mui/icons-material/FilterList';
import { updateParams } from '../../utils';
import { Can } from '../../Context/Can';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import Filter from '../Common/Filter';
import useBackActionDetection from '../../utils/UseBackActionDetection';

export default function Report() {
    let navigate = useNavigate()
    const location = useLocation();
    const goToRoutes = (routeApi) => {
        navigate(routeApi, { replace: false });
    }

    const isBackAction = useBackActionDetection();
    let { client_id } = useParams()
    const dispatch = useDispatch();
    const [responseData, setResponseData] = useState([])
    const [sectionList, setSectionList] = useState([])
    const [showFilterDialog, setShowFilterDialog] = useState(false);
    const [queryParams, setQueryParams] = useSearchParams();
    const existingParams = Object.fromEntries([...queryParams])
    const [showLoader, setShowLoader] = useState(true);
    const [hoveredRowId, setHoveredRowId] = useState(null);
    const [totalPages, setTotalPages] = useState(null)
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(null);

    const tableColumns = ['Sl.No.','Report Name', 'Section', 'Facility', 'Type', 'Users', '']
    let tableContent = null;
    const fetchData = () => {
        const params = {
            ...existingParams,
            title: existingParams?.search,
            page: existingParams?.page,
        }
        dispatch(getAllReports(client_id, params)).then((res) => {
            if (res && res.status === 200) {
                const reportData = res.data.data;
                setResponseData(reportData)
                setTotalPages(res.data.totalPages)
                setTotalCount(res.data.totalItems);
                setCurrentPage(res.data.currentPage)
                const sections = [...new Set(res.data.allData.map(item => item.section_name))];
                setSectionList(sections);
            }
            setShowLoader(false)
        });
    };

    useEffect(() => {
        if (existingParams?.page) {
            return
        }
        updateQueryParams({ page: 1 })
        fetchData();
    }, [queryParams]);

    useEffect(() => {
        fetchData();
    }, [queryParams]);

    const updateQueryParams = (params) => {
        const searchParams = updateParams(existingParams, params)
        navigate({ search: searchParams.toString() }, { replace: true });
    }

    const handleSearch = (value) => {
        setShowLoader(true)
        updateQueryParams({ search: value, page: 1 });
    }

    const handlePageChange = (event, newPage) => {
        setShowLoader(true)
        updateQueryParams({ page: newPage });
    };

    const showAllowedUsersList = (users) => (
        users?.map((item, idx) => {
            if (idx < 3) {
                return <span>
                    {item.email}
                    {idx < 2 && idx < users.length - 1 ? ', ' : ''}
                </span>
            }
        })
    )

    const itemsPerPage = 20;
    let facilityList = []
    if (showLoader) {
        facilityList = (
            <TableRow>
                <TableCell colSpan={7} align="center">
                    <CircularProgress />
                </TableCell>
            </TableRow>
        )
    } else if (responseData && responseData.length > 0) {
        facilityList = responseData.map((item, index) => {
            return (
                <TableRow
                    onMouseEnter={() => {
                        setHoveredRowId(item?.id);
                    }}
                    onMouseLeave={() => {
                        setHoveredRowId(null);
                    }}
                >
                    <TableCell>{(currentPage - 1) * itemsPerPage + index + 1}</TableCell>
                    <TableCell className="report-name">{item?.report_name}</TableCell>
                    <TableCell>{item?.section_name || '-'}</TableCell>
                    <TableCell>{item?.facility?.facility_name || '-'}</TableCell>
                    <TableCell>{item?.type}</TableCell>
                    <TableCell>
                        {item?.user_id?.length === 0 ? '-' : showAllowedUsersList(item?.user_id)}
                    </TableCell>

                    <TableCell>
                        <Box component="div" display="flex" justifyContent='right' >
                            <Can do="manage" on="EditReport">
                                <Tooltip title="Edit">
                                    <IconButton aria-label="edit"
                                        style={{ opacity: hoveredRowId === item?.id ? 1 : 0, paddingLeft: '0px' }}
                                        onClick={() => {
                                            const searchValue = queryParams.get('search');
                                            const sectionValue = queryParams.get('section');
                                            const typeValue = queryParams.get('type');
                                            const facilityValue = queryParams.get('facility_id')

                                            const state = {
                                                status: searchValue || sectionValue || typeValue || facilityValue
                                            };
                                            navigate(`${location.pathname}/edit/${item.id}`, { state: state })
                                        }}
                                    >
                                        <Edit className="cursor-pointer"
                                            style={{ color: 'black' }}
                                        />
                                    </IconButton>
                                </Tooltip>
                            </Can>
                        </Box>
                    </TableCell>
                </TableRow>
            )
        });
    } else {
        facilityList = (
            <TableRow>
                <TableCell colSpan={7} align="center">
                    <h5 className="w3-text-grey">No Data Found</h5>
                </TableCell>
            </TableRow>
        );
    }

    tableContent = facilityList
    const reportTypes = ['File']

    return (
        <Grid container>
            <Grid item md={12}>
                <Grid item container spacing={8} paddingY={2}>
                    <Grid item md={6}>
                        <Can do="manage" on="CreateReport">
                            <Button
                                variant="contained"

                                onClick={() => { goToRoutes(`${location.pathname}/create`) }}
                            >Create New Report</Button>
                        </Can>
                    </Grid>
                    <Grid item md={6} container justifyContent="flex-end" direction="row">
                        <Grid item container md={12} direction="row">
                            <Grid item md={11} container justifyContent="flex-end">
                                <h6 style={{ paddingRight: '5px', fontSize: '15px' }}>Search By: </h6>
                                <SearchBox
                                    value={queryParams.get('search') || ''}
                                    placeholder='Report Name'
                                    search={handleSearch}
                                    isBrowserBackAction={isBackAction}
                                />
                            </Grid>
                            <Grid item container md={1} justifyContent="flex-end">
                                <IconButton onClick={() => setShowFilterDialog(true)} title="Apply Filter">
                                    <FilterListIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Filter
                    filterArea="Reports"
                    sectionListOption={sectionList}
                    reportTypesOption={reportTypes}
                    showFilterDialog={showFilterDialog}
                    setShowFilterDialog={setShowFilterDialog}
                    updateQueryParams={updateQueryParams}
                    queryParams={queryParams}
                />
                <Grid paddingX={0} paddingY={1}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table" style={{ tableLayout: 'fixed' }}>
                            <TableHead>
                                <TableRow>
                                    {tableColumns.map((tCol, idx) => (
                                        <TableCell key={idx} style={{ fontSize: '14px', fontWeight: 'bold', width: idx === 0 ? '98px' : 'auto' }}>{tCol}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableContent}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                {( responseData && (responseData.length > 0) && !showLoader ) && ( 
                        <Grid container item xs={12} style={{ display: 'flex', justifyContent: 'center', marginBottom: '30px' }}>
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '24px' }}>
                                <span style={{ fontFamily: '"Helvetica Neue"', fontSize: '13px', color: '#3d3d3d' }}>
                                    {Math.min((parseInt(existingParams.page) - 1) * itemsPerPage + 1, totalCount)}-
                                    {Math.min(parseInt(existingParams.page) * itemsPerPage, totalCount)} of {totalCount}
                                </span>
                            </Grid>
                            <Pagination
                                page={parseInt(existingParams?.page) || 1}
                                count={totalPages}
                                variant="outlined"
                                shape="rounded"
                                color="primary"
                                onChange={handlePageChange}
                            />
                        </Grid>
                    )}
            </Grid>
        </Grid>
    )
}