import { Box, Button, Card, Chip, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, IconButton, Pagination, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SearchBox from '../Common/SearchBox';
import { Navigate, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import FilterListIcon from '@mui/icons-material/FilterList';
import { getAllMaintenanceLogs } from '../../Redux/actions';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { Edit } from '@mui/icons-material';
import { calculateOverDue, updateParams } from '../../utils';
import ImagePlaceholder from '../../Images/image-placeholder.png'
import CloseIcon from '@mui/icons-material/Close';
import Slider from 'react-slick';
import BackArrow from '../../Images/BackArrow.png';
import Filter from '../Common/Filter';

export default function MaintenanceLog() {

    const { client_id, tabId, id } = useParams();
    const [queryParams, setQueryParams] = useSearchParams();
    const existingParams = Object.fromEntries([...queryParams])
    const [showLoader, setShowLoader] = useState(true)
    const [responseData, setResponseData] = useState([])
    const [hoveredRowId, setHoveredRowId] = useState(null);
    const [totalCount, setTotalCount] = useState(0);
    const [totalPages, setTotalPages] = useState(null)
    const [isSearchOrFilterApplied, setIsSearchOrFilterApplied] = useState(false);
    const [currentPage, setCurrentPage] = useState(null);
    const [selectedImage, setSelectedImage] = useState([]);
    const [openImageDialog, setOpenImageDialog] = useState(false);
    const [imageLoading, setImageLoading] = useState({});
    const [disablePrev, setDisablePrev] = useState(true);
    const [disableNext, setDisableNext] = useState(false);
    const [showFilterDialog, setShowFilterDialog] = useState(false);

    const tableColumns = ['Sl. No.','Description', 'Maintenance Schedule','Overdue', 'Engineer', 'Planned Date', 'Completion Date', 'Approver', 'Attachments', 'Maintenance Cost', 'Status', '']

    let navigate = useNavigate()
    const goToRoutes = (routeApi) => {
        navigate(routeApi, { replace: true });
    }
    const location = useLocation();
    const dispatch = useDispatch();

    let tableContent = null;

    useEffect(() => {
        if (existingParams?.page) {
            return
        }
        updateQueryParams({ page: 1 })
        fetchData();
    }, [queryParams]);

    useEffect(() => {
        setIsSearchOrFilterApplied(false);
        fetchData();
    }, [queryParams])

    const fetchData = () => {
        const params = {
            ...existingParams,
            title: existingParams?.search,
            page: isSearchOrFilterApplied ? 1 : existingParams?.page,
        }
        dispatch(getAllMaintenanceLogs(id, params)).then((res) => {
            if (res && res.status === 200) {
                setResponseData(res?.data?.maintenanceData)
                setTotalPages(res?.data?.totalPages)
                setTotalCount(res?.data?.totalItems);
                setCurrentPage(res?.data?.currentPage)
                setShowLoader(false)
            }
        });
    };

    const updateQueryParams = (params) => {
        const searchParams = updateParams(existingParams, params)
        navigate({ search: searchParams.toString() }, { replace: true });
    }

    const handleSearch = (value) => {
        updateQueryParams({ search: value, page: 1 });
        setIsSearchOrFilterApplied(true);
    }

    const handlePageChange = (event, newPage) => {
        updateQueryParams({ page: newPage });
    };

    function SampleNextArrow(props) {
        const { className, style, onClick, disableNext } = props;
        return (
            <img
                src={BackArrow}
                alt="next"
                className={className}
                style={{ ...style, right: 0, transform: 'rotate(180deg)', display: disableNext ? 'none' : 'block', marginRight: '10px' }}
                onClick={onClick}
            />
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick, disablePrev } = props;
        return (
            <img
                src={BackArrow}
                alt="prev"
                className={className}
                style={{ ...style, left: 0, display: disablePrev ? 'none' : 'block', marginLeft: '10px' }}
                onClick={onClick}
            />
        );
    }

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow disableNext={disableNext} />,
        prevArrow: <SamplePrevArrow disablePrev={disablePrev} />,
        centerMode: true,
        centerPadding: '0',
        beforeChange: (current, next) => {
            setDisablePrev(next === 0);
            setDisableNext(next === selectedImage?.length - 1);
        },
        onInit: () => {
            setDisableNext(selectedImage?.length <= 1);
        }
    };

    const handleImageClick = (images) => {
        setSelectedImage(images);
        setOpenImageDialog(true);
    };

    const handleCloseImageDialog = () => {
        setOpenImageDialog(false);
    };

    const getStatusClassName = (item) => {
        if (item === 'Pending') {
            return 'w3-red';
        } else if (item === 'Completed') {
            return 'w3-green';
        } else{
            return 'w3-yellow';
        }
    };

    let maintenanceLogList = []
    const itemsPerPage = 20;

    if (showLoader) {
        maintenanceLogList = (
            <TableRow>
                <TableCell colSpan={12} align="center">
                    <CircularProgress />
                </TableCell>
            </TableRow>
        )
    } else if (responseData && responseData.length > 0) {
        maintenanceLogList = responseData.map((item, index) => {
                const overDue = calculateOverDue(item.completion_time, item?.asset?.operation_start_date, item?.offset_time_days )
            return (
                <TableRow
                    onMouseEnter={() => {
                        setHoveredRowId(item?.id);
                    }}
                    onMouseLeave={() => {
                        setHoveredRowId(null);
                    }}
                >
                    <TableCell>{(currentPage - 1) * itemsPerPage + index + 1}</TableCell>
                    <TableCell style={{ overflow: 'hidden',textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} title={item?.description}>{item?.description}</TableCell>
                    <TableCell>
                        <div>{`${item?.offset_time_days} Days`}</div>
                        <div style={{ fontSize: '10px'}}>{`(${overDue?.actualMaintenanceDate})`}</div>
                    </TableCell>
                    <TableCell>{overDue?.overDue > 0 ? `${overDue?.overDue} Day(s)` : '--'}</TableCell>
                    <TableCell style={{ overflow: 'hidden',textOverflow: 'ellipsis'}} title={item?.maintenance_engineer}>{item?.maintenance_engineer}</TableCell>
                    <TableCell>{new Date(item?.planned_date).toLocaleString(undefined, {
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric',
                                        })}</TableCell>
                    <TableCell>{item?.completion_time ? new Date(item?.completion_time).toLocaleString(undefined, {
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric',
                                        }) : '--' }</TableCell>
                    <TableCell style={{ overflow: 'hidden',textOverflow: 'ellipsis'}} title={item?.approved_by}>{item?.approved_by}</TableCell>
                    <TableCell style={{ textAlign: 'center'}}>
                        {item?.media && item?.media.length > 0 ? (
                            <img
                                src={ImagePlaceholder} 
                                alt="Attachment"
                                style={{ cursor: 'pointer', maxWidth: '20px', marginRight: '5px' }}
                                onClick={() => handleImageClick(item?.media)}
                            />
                        ) : ('--') }
                    </TableCell>
                    <TableCell style={{ overflow: 'hidden',textOverflow: 'ellipsis'}}>{item?.cost ? `₹ ${item?.cost}` : '--'}</TableCell>
                    <TableCell title={item?.status}>
                        <Chip 
                        style={{ width: '78px', display: 'flex', justifyContent: 'center'}}
                        size="small"
                        className={getStatusClassName(item?.status)}
                        label={item?.status}
                        />
                    </TableCell>
                    <TableCell>
                        <Box component="div" display="flex" justifyContent={"right"} >
                            {!(window.location.href.includes('details')) && (<Tooltip title="Edit">
                                <IconButton aria-label="edit"
                                    style={{ opacity: hoveredRowId === item?.id ? 1 : 0, paddingLeft: '0px' }}
                                    onClick={() => {
                                        navigate(`/client/${client_id}/client-assets/${id}/${tabId}/${item?.id}/edit`)
                                        localStorage.setItem('maintenanceLogQueryParams', queryParams);
                                    }}
                                >
                                    <Edit className="cursor-pointer"
                                        style={{ color: 'black' }}
                                    />
                                </IconButton>
                            </Tooltip>)}
                        </Box>
                    </TableCell>
                </TableRow>
            )
        });
    } else if (!showLoader && responseData.length <= 0) {
        maintenanceLogList  = (
            <TableRow>
                <TableCell colSpan={12} align="center">
                    <h5 className="w3-text-grey">No Logs Added Yet</h5>
                </TableCell>
            </TableRow>
        );
    }

    tableContent = maintenanceLogList

    return (
        <div className='w3-padding-large'>
            <Grid container>
                <Grid item md={12}>
                    {!(window.location.href.includes('details')) && (<Grid item container spacing={8} paddingY={2}>
                        <Grid item md={6}>
                            <Button
                                variant="contained"
                                onClick={() => { 
                                    if (localStorage.getItem('maintenanceLogQueryParams')) {
                                        localStorage.removeItem('maintenanceLogQueryParams');
                                    }
                                    navigate(`/client/${client_id}/client-assets/${id}/${tabId}/create`) 
                                }}
                            >Create New Log</Button>
                        </Grid>
                        <Grid item md={6} container justifyContent="flex-end" direction="row">
                            <Grid item container md={12} direction="row">
                                <Grid item md={11} container justifyContent="flex-end">
                                    <h6 style={{ paddingRight: '5px', fontSize: '15px' }}>Search By: </h6>
                                    <SearchBox
                                        value={queryParams.get('search') || ''}
                                        placeholder='Description'
                                        search={handleSearch}
                                    />
                                </Grid>
                                <Grid item container md={1} justifyContent="flex-end">
                                    <IconButton 
                                        onClick={() => setShowFilterDialog(true)} 
                                        title="Apply Filter">
                                        <FilterListIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>)}
                    <Filter
                        filterArea="Maintenance Logs"
                        showFilterDialog={showFilterDialog}
                        setShowFilterDialog={setShowFilterDialog}
                        updateQueryParams={updateQueryParams}
                        queryParams={queryParams}
                        setIsSearchOrFilterApplied={setIsSearchOrFilterApplied}
                    />
                    <Grid paddingX={0} paddingY={1}>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table" style={{ tableLayout: 'fixed' }}>
                                <TableHead>
                                    <TableRow>
                                    {tableColumns.map((tCol,idx) => (
                                        <TableCell style={{ fontSize: '13px', fontWeight: 'bold', width: (idx === 0 || idx === 11) ? '46px' : 'auto', verticalAlign: 'baseline' }}>{tCol}</TableCell>
                                    ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tableContent}
                                </TableBody>    
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                {( responseData && responseData.length > 0 ) && ( 
                    <Grid container item xs={12} style={{ display: 'flex', justifyContent: 'center', marginBottom: '30px' }}>
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '24px' }}>
                            <span style={{ fontFamily: '"Helvetica Neue"', fontSize: '13px', color: '#3d3d3d' }}>
                                {Math.min((parseInt(existingParams.page) - 1) * itemsPerPage + 1, totalCount)}-
                                {Math.min(parseInt(existingParams.page) * itemsPerPage, totalCount)} of {totalCount}
                            </span>
                        </Grid>
                        <Pagination
                            page={parseInt(existingParams?.page) || 1}
                            count={totalPages}
                            variant="outlined"
                            shape="rounded"
                            color="primary"
                            onChange={handlePageChange}
                        />
                    </Grid>
                )}
            </Grid>
            <Dialog open={openImageDialog} onClose={handleCloseImageDialog}>
            <DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseImageDialog}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'gray',
                        zIndex: 100
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <div className="slider-container" style={{ overflow: 'hidden', position: 'relative' }}>
                    <Slider {...settings}>
                        {selectedImage?.map((item, idx) => (
                            <div key={idx} className="slider-component">
                                {imageLoading[idx] && (
                                    <CircularProgress
                                        style={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            width: '40px',
                                            height: '40px',
                                            zIndex: 100
                                        }}
                                    />
                                )}
                                <img
                                    src={item?.path}
                                    alt="Attachment"
                                    className="slider-image"
                                    onLoad={() => setImageLoading(prev => ({ ...prev, [idx]: false }))}
                                    onError={() => setImageLoading(prev => ({ ...prev, [idx]: false }))}
                                    onLoadStart={() => setImageLoading(prev => ({ ...prev, [idx]: true }))}
                                />
                            </div>
                        ))}
                    </Slider>
                </div>
            </DialogContent>
        </Dialog>
        </div>
    )
}