import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import moment from 'moment-timezone';
import { FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { createLocation, getClientLocationById, updateLocation } from '../../Redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { setStoreData } from '../../Redux/actions';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { Can } from '../../Context/Can';

export default function Location() {

    const location = useLocation();
    const locationState = location.state;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { client_id, id } = useParams();

    const intialForm = {
        name: '',
        map_url: '',
        timezone: '',
        timezoneOffset: ''
    }
    const [form, setForm] = useState(intialForm);
    const error = {};
    const [errors, setErrors] = useState(error);
    const [disableButton, setDisableButton] = useState(false);
    const timezoneList = moment.tz.names();
    
    const [viewPermission, setViewPermission] = useState(false);
    const [userAccess, setUserAccess] = useState([])


    const state = useSelector(st => st)
    const { role } = state;
    const userRole = role?.data?.data;

    const [itemNotFound, setItemNotFound] = useState(false);

    const fetchData = () => {
        if (id) {
            dispatch(getClientLocationById(client_id, id)).then((res) => {
                const response = res.data.data;
                if (!response) {
                    setItemNotFound(true);
                    return;
                }
                const { id, name, map_url,timezone, time_offset } = response;
                const locationData = {
                    id,
                    name,
                    map_url,
                    timezone,
                    timezoneOffset: time_offset,
                }
                setForm(locationData);
            })
        }
    }

    useEffect(() => {
        if (userRole?.length > 0) {
            let arr = []
            for (const item of userRole) {
                if (item.role === "admin") {
                    item.adminAccess = {
                        edit: true,
                        delete: true,
                        id: '',
                        role: item.role
                    };
                } else if (item.permission && Array.isArray(item.permission) && item.permission.length > 0) {
                    item.adminAccess = {};
                    for (const permission of item.permission) {
                        if (permission.permissions.edit) {
                            item.adminAccess.edit = true;
                            item.adminAccess.id = permission.itemId;
                            item.adminAccess.role = ''
                        }
                        if (permission.permissions.delete) {
                            item.adminAccess.delete = true;
                            item.adminAccess.id = permission.itemId;
                            item.adminAccess.role = ''
                        }
                        if (permission.permissions.view) {
                            item.adminAccess.view = true;
                            item.adminAccess.id = permission.itemId;
                            item.adminAccess.role = ''
                        }
                    }
                }
                arr.push(item)
            }
            if (arr?.length > 0) {
                setUserAccess(arr)
            }
        }
    }, [userRole])

    useEffect(() => {
        let canEdit = false;
        let canDelete = false;
        let canView = false
        let admin = false
        if (userAccess?.length > 0) {
            userAccess?.find(uItem => {
                uItem.permission?.find(pItem => {
                    if (pItem.itemId === id) {
                        if (pItem.permissions.edit) {
                            canEdit = true
                        }
                        if (pItem.permissions.view) {
                            canView = true
                        }

                        if (pItem.permissions.delete) {
                            canDelete = true
                        }
                    }
                    if(pItem.itemId === client_id && pItem.type === 'client' && pItem.permissions.edit) {
                        canEdit = true;
                        canDelete = true;
                    }
                    if(pItem.itemId === client_id && pItem.type === 'client' && pItem.permissions.view) {
                        canView = true
                    }
                })
            })

            userAccess.find(item => {
                if (item.role === 'admin') {
                    canEdit = true;
                    canDelete = true;
                    canView = false;
                    admin = true;
                }
            })
            if(canView && !canEdit && !admin) {
                setViewPermission(true);
            }
        }
    }, [userAccess])

    useEffect(() => {
        fetchData();
    }, [id]);

    const validateData = () => {
        const err = { ...errors };

        let hasErrors = false;
        if (!form.map_url) {
            hasErrors = true;
            err.map_url = 'This field is mandatory';
        }
        if (!form.map_url) {
            hasErrors = true;
            err.map_url = 'This field is mandatory';
        } else if (!/^https:\/\/.*maps/i.test(form.map_url)) {
            hasErrors = true;
            err.map_url = 'Enter a valid Map URL';
        }
        if (!form.timezone) {
            hasErrors = true;
            err.timezone = 'This field is mandatory';
        }

        if (hasErrors) {
            setErrors(err);
            return false;
        }
        return true;
    };

    const updateResponse = (resp, message) => {
        const res = resp && resp.data;
        let msg = res?.message;
        if (msg) {
            let type = "fail";
            if (res && res.success) {
                msg = message;
                type = "success";
            }
            dispatch(setStoreData('alertMessage', {
                msg, type,
                handleClose: () => handleCloseDialog(),
            }))
        }

    };
    const handleSubmit = () => {
        setDisableButton(true)
        const checkForm = validateData();
        if (checkForm) {
            const { name, map_url, timezone, timezoneOffset } = form;
            const data = {
                name: name,
                map_url: map_url,
                client_id,
                timezone,
                time_offset: timezoneOffset
            }
            if (id) {
                dispatch(updateLocation(data, id)).then((res) => {
                    setDisableButton(false)
                    updateResponse(res, res?.data?.message);
                })
            } else {
                dispatch(createLocation(data)).then((res) => {
                    setDisableButton(false)
                    updateResponse(res, res?.data?.message);
                })
            }
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        const data = Object.assign({}, form);
        data[name] = value;
        const err = Object.assign({}, errors);
        if (err[name]) {
            err[name] = null;
        }
        setErrors(err);
        if (name === 'timezone') {
            data.timezone = value;
            data.timezoneOffset = moment.tz(value).utcOffset() * 60;
        }
        setForm(data);
        if (disableButton) {
            setDisableButton(false)
        }
    }


    const handleCloseDialog = () => {
        dispatch(setStoreData('alertMessage', null))
        locationState?.status ? navigate(-1) : navigate(`/client/${client_id}/settings/locations`);
    }

    if (id && itemNotFound) {
        return <Grid container >
            <Grid item md={12} className='w3-padding' style={{ display: 'flex', justifyContent: 'center' }}>
                <h5 className='w3-text-grey'>
                    No Data Found
                </h5>
            </Grid>
        </Grid>

    }
    
    return (
        <Paper elevation={1} style={{ margin: '16px 0px' }}>
            <Grid container >
                <Grid container className='w3-padding'>
                    <Grid item md={12} style={{ display: 'flex'}}>
                        <IconButton
                            style={{ marginBottom: '8px'}}
                            onClick={() => locationState?.status ? navigate(-1) : navigate(`/client/${client_id}/settings/locations`)}
                            color="inherit">
                            <ArrowBackIosIcon fontSize='small'/>
                        </IconButton>
                        <h6 style={{ paddingBottom: '8px' }}>
                            {id ? 'Edit Location' : 'Create New Location'}</h6>
                    </Grid>
                    <Grid container item md={12}>
                        <Grid item md={12} className="w3-margin-bottom" style={{ display: 'flex', flexDirection: 'row', }}>
                            <TextField
                                fullWidth
                                label="Name"
                                name="name"
                                required
                                disabled
                                value={form.name}
                                onChange={handleChange} />
                        </Grid>
                        {errors.name && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.name}</div>}
                        <Grid item md={12} className="w3-margin-bottom" style={{ display: 'flex', flexDirection: 'row', }}>
                            <TextField
                                fullWidth
                                label="Map URL"
                                name="map_url"
                                required
                                value={form.map_url}
                                onChange={handleChange} />
                        </Grid>
                        {errors.map_url && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.map_url}</div>}
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel required id="timezone-select-label">Select Timezone</InputLabel>
                                <Select
                                    labelId="timezone-select-label"
                                    id="timezone"
                                    name="timezone"
                                    value={form.timezone}
                                    onChange={handleChange}
                                    label="Select Timezone"
                                    MenuProps={{
                                        disableScrollLock: true,
                                    }}
                                >
                                    <MenuItem value="">
                                        <em>Select a timezone</em>
                                    </MenuItem>
                                    {timezoneList.map((tz) => (
                                        <MenuItem key={tz} value={tz}>
                                            {`${tz} (UTC${moment.tz(tz).format('Z')})`}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {errors.timezone && <div style={{ marginTop: 2 }} className='error-text w3-margin-bottom'>{errors.timezone}</div>}
                        <Grid item xs={12} style={{marginTop: 20}}>
                            <Grid container className='w3-margin-bottom' spacing={2} justifyItems="space-between">
                                <Grid item container md={12} justifyContent="flex-end">
                                    <Grid item>
                                        <Button
                                            variant="outlined"
                                            className="w3-margin-right"
                                            onClick={() => locationState?.status ? navigate(-1) : navigate(`/client/${client_id}/settings/locations`)}
                                        >
                                            Cancel
                                        </Button>
                                        {!viewPermission && <Can do="manage" on="EditLocation">
                                        <Button
                                            disabled={disableButton}
                                            color="primary"
                                            variant="contained"
                                            onClick={handleSubmit}
                                        >
                                            Submit
                                        </Button>
                                        </Can>}

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    )
}