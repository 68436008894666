import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getAllMeter} from '../../Redux/actions';
import React, { useEffect, useState } from 'react';
import { Tooltip, IconButton, CircularProgress, Typography, Chip, Stack, Card } from '@mui/material';
import { useParams } from 'react-router-dom';
import SearchBox from '../Common/SearchBox';
import FilterListIcon from '@mui/icons-material/FilterList';
import { updateParams } from '../../utils';
import { Can } from '../../Context/Can';
import Pagination from '@mui/material/Pagination';
import Filter from '../Common/Filter';
import moment from 'moment';
import useBackActionDetection from '../../utils/UseBackActionDetection';


export default function Meters() {

    let navigate = useNavigate();
    const location = useLocation();
    const goToRoutes = (routeApi) => {
        navigate(routeApi, { replace: false });
    }
    const isBackAction = useBackActionDetection();

    const dispatch = useDispatch()
    const [responseData, setResponseData] = useState([])
    const [locationData, setLocationData] = useState(null)
    const [clientFacilityData, setClientFacilityData] = useState(null)
    const [projectData, setProjectData] = useState(null)
    const [parameterGrp, setParameterGrp] = useState([])
    const [alertData, setAlertData] = useState([])
    const [showFilterDialog, setShowFilterDialog] = useState(false);
    const [showLoader, setShowLoader] = useState(true);

    const [queryParams, setQueryParams] = useSearchParams();
    const existingParams = Object.fromEntries([...queryParams])
    const [filterOptions, setFilterOptions] = useState(existingParams); 
    const [totalPages, setTotalPages] = useState(null)
    const [isSearchOrFilterApplied, setIsSearchOrFilterApplied] = useState(false);
    const [totalCount, setTotalCount] = useState(0);

    let content = null;

    const parameterGroup = [
        { label: 'Water Quality', value: 'water_quality' },
        { label: 'Water Quantity', value: 'water_quantity' },
        { label: 'Energy', value: 'energy' },
        { label: 'Pressure', value: 'pressure' },
        { label: 'Temperature', value: 'temperature' },
        { label: 'Level', value: 'level' },
        { label: 'Equipment Status', value: 'equipment_status' },
        { label: 'Air Quality', value: 'air_quality' },
        { label: 'Auto Chlorination', value: 'auto_chlorination' },
    ];


    const fetchData = () => {
        const params = {
            ...existingParams,
            title: existingParams?.search,
            page: isSearchOrFilterApplied ? 1 : existingParams?.page,
        }
        dispatch(getAllMeter(client_id, params)).then((res) => {
            if (res && res.status === 200) {
                setResponseData(res.data.data)

                setTotalPages(res.data.totalPages)
                setTotalCount(res.data.totalItems);

                const parameterGrps = res.data.allData.map((item) => item.meter_group);
                const uniqueParameterGrps = [...new Set(parameterGrps)];
                const result = uniqueParameterGrps
                    .map((value) => {
                        const match = parameterGroup.find((group) => group.value === value);
                        return match ? { label: match.label, value: match.value } : null;
                    })
                    .filter((item) => item !== null && item.value !== '');
                setParameterGrp(result);

                const alert = res.data.allData.filter((item) => item.alert_priority !== "null")
                    .map((item) => item.alert_priority);
                const uniqueAlerts = [...new Set(alert)];
                setAlertData(uniqueAlerts)
                const meterLocations = [];
                const meterFacilities = [];
                const meterProjects = [];

                res.data.data.forEach((item) => {
                    item.projectMeter?.forEach((pItem) => {
                        const location = pItem?.facilities?.location;
                        if (!meterLocations.some((loc) => loc.id === location.id)) {
                            meterLocations.push(location);
                        }

                        const facility = pItem.facilities;
                        if (!meterFacilities.some((fac) => fac.id === facility.id)) {
                            meterFacilities.push(facility);
                        }

                        const project = pItem.project;
                        if (!meterProjects.some((proj) => proj.pid === project.pid)) {
                            meterProjects.push(project);
                        }
                    });
                });
            }
            setShowLoader(false);
        });
    };

    useEffect(() => {
        if (existingParams?.page) {
            return
        }
        updateQueryParams({ page: 1 })
        fetchData();
    }, [queryParams]);

    useEffect(() => {
        setIsSearchOrFilterApplied(false);
        fetchData();
    }, [queryParams])

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilterOptions((prevFilters) => ({
            ...prevFilters,
            [name]: value,
        }));
    };

    const handlePageChange = (event, newPage) => {
        setShowLoader(true)
        updateQueryParams({ page: newPage });
    };

    const updateQueryParams = (params) => {
        const searchParams = updateParams(existingParams, params)
        navigate({ search: searchParams.toString() }, { replace: true });
    }

    const handleSearch = (value) => {
        setShowLoader(true)
        updateQueryParams({ search: value, page: 1 });
        setIsSearchOrFilterApplied(true);
    }

    let { client_id: client_id } = useParams()

    const handleEditMeter = (item) => {
        const location_id = queryParams.get('location_id');
        const searchValue = queryParams.get('search');
        const facilityValue = queryParams.get('facility_id');
        const projectValue = queryParams.get('project_id');
        const groupValue = queryParams.get('meterGrp');
        const alertValue = queryParams.get('alert');
        const tagValue = queryParams.get('meterTags');
        const hideChartValue = queryParams.get('hideChart');
        const state = {
            status: location_id || searchValue || facilityValue || projectValue || groupValue || alertValue || tagValue || hideChartValue,
        };
        localStorage.setItem('meterQueryParams', queryParams);
        navigate(`/client/${client_id}/meters/trending-analysis/${item.mid}`, { state: state })
    }

    let meterList = []
    if (responseData && (responseData.length > 0) && !showLoader) {
        meterList = responseData.map((item) => {
            let project = [];
            let tags = [];
            item?.projectMeter?.map(meterData => {
                project.push({
                    project: meterData?.project?.short_name,
                    facility: meterData?.facilities?.facility_name,
                    location: meterData?.facilities?.location?.name
                });
            });

            project = [...new Set(project)];
            const matchedParameter = parameterGroup?.find(param => param.value === item?.meter_group);
            const paramGrp = matchedParameter?.label;
            let dataColor ={
                background:'#ed2424',
                color:'white'
            };
            const diff = Date.now() - item.data_till
            if (diff < 36e5) {
                dataColor ={
                    background:'#52ec52',
                    color:'black'
                };
            } else if (diff < 6*36e5) {
                dataColor ={
                background:'#c0e392',
                color:'black'
            };
            } else if (diff < 12*36e5) {
                dataColor ={
                    background:'#e2d306',
                    color:'white'
                };
            }  else if(item.data_till > 0) {
                dataColor ={
                    background:'#000',
                    color:'white'
                };
            }

            const rcvd = item.data_till == 0  ? 'never' : moment(item.data_till).format('YYYY-MM-DD HH:mm:ss');
            return (<Card key={item?.mid} sx={{ marginBottom: "10px" }}
                style={{ cursor: 'pointer' }}
               
                onClick={() => handleEditMeter(item)}
            >
                <Grid container direction={"column"} spacing={2} padding={2}>
                    <Grid item container justifyContent={"space-between"}>
                        <Grid sx={{ marginBottom: '8px' }} container md={12} item spacing={1} direction={'row'} flexWrap={'wrap'}>
                            <Grid container item md={8} direction={'row'}>
                                <h5 style={{ marginTop: '0px', marginRight: '20px' }}>{item?.metername}</h5>
                                {item?.status && (
                                    <Tooltip title="Meter Status">
                                        <Chip size="small" label={item?.status} />
                                    </Tooltip>
                                )}
                            </Grid>
                            <Grid container item md={4} justifyContent={"flex-end"} spacing={1} >
                                <Stack direction={"row"} spacing={1}>
                                    <Tooltip title="meter id">
                                        <Chip size="small" color="primary" label={` ${item?.meterid}`} />
                                    </Tooltip>

                                    <Tooltip title="Priority">
                                    <Chip size='small' color="secondary" label={`${item?.alert_priority && item?.alert_priority != 'null' ? item.alert_priority : 'Not Set'}`} />
                                    </Tooltip>
                                    
                                    <Tooltip title="Parameter Group">
                                        <Chip size="small" label={paramGrp} />
                                    </Tooltip>
                                    <Tooltip title="Projects">
                                        <Chip size="small" sx={{color:'white',background:'#02abd1'}} label=  {item.projectMeter.map(item => item.project_id).join(", ")}></Chip>
                                    </Tooltip>
                                    <Tooltip title={`Last data received : ${rcvd} `}>
                                        <Chip size="small" sx={dataColor} label={ item.data_till == 0 ? 'never' :  moment(item.data_till).fromNow()} ></Chip>
                                    </Tooltip>
                                </Stack> 
                            </Grid>
                        </Grid>
                       {item.tags.length > 0  && <Grid container md={12} item spacing={1} direction={'row'} flexWrap={'wrap'}>
                            <Grid item>
                                {item.tags.map(tag => <Chip
                                    key={`chip_${tag.tag_text}`}
                                    style={{ margin: '0px 4px 4px 0px' }} size='small'
                                    variant='outlined' color='primary' label={tag.tag_text}
                                />)}
                            </Grid>
                        </Grid>}
                    </Grid>
                    <Grid item container md={12} flexDirection="row" alignItems="flex-end" justifyContent="space-between">
                        <Grid item md={8}>
                            <Stack>
                                {project?.map(item => (<Typography key={`details_${item.project}`} noWrap>
                                    {item.project},  {item.facility}, {item.location}
                                </Typography>))}
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
            )
        })
    } else if (showLoader) {
        meterList = (
            <Grid container justifyContent={"center"}>
                <CircularProgress />
            </Grid>
        )
    } else if (responseData && responseData?.length === 0 && [...queryParams.keys()].some(key => key !== 'page')) {
        meterList = (
            <Grid container justifyContent={"center"} style={{ marginTop: '4%' }}>
                <h5 className='w3-text-grey'>  No Data Found
                </h5>
            </Grid>
        )
    } else {
        meterList = (
            <Grid container justifyContent={"center"} style={{ marginTop: '4%' }}>
                <h5 className='w3-text-grey'>  No IOT Meters deployed on client projects.
                </h5>
            </Grid>
        )
    }

    content = meterList
    const itemsPerPage = 20;

    return (
        <div className='w3-padding-large'>
            <Grid container>
                <Grid item md={12}>
                    <Grid item container spacing={8} paddingY={2}>
                        <Grid item md={6}>
                            <Can do="manage" on="AddMeter">
                                <Button
                                    variant="contained"
                                    onClick={() => { goToRoutes(`/client/${client_id}/meters/create`) }}
                                >Add New Meter</Button>
                            </Can>
                        </Grid>
                        <Grid item md={6} container justifyContent="flex-end" direction="row">
                            <Grid item container md={12} direction="row">
                                <Grid item md={11} container justifyContent="flex-end">
                                    <h6 style={{ paddingRight: '5px', fontSize: '15px' }}>Search By: </h6>
                                    <SearchBox
                                        value={queryParams.get('search') || ''}
                                        placeholder='Meter Name / Meter ID'
                                        search={handleSearch}
                                        isBrowserBackAction={isBackAction}
                                    />
                                </Grid>
                                <Grid item container md={1} justifyContent="flex-end">
                                    <IconButton onClick={() => setShowFilterDialog(true)} title="Apply Filter">
                                        <FilterListIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Filter
                        filterArea="Meters"
                        locationDataOption={locationData}
                        clientFacilityOption={clientFacilityData}
                        parameterGrpOption={parameterGrp}
                        alertOption={alertData}
                        showFilterDialog={showFilterDialog}
                        setShowFilterDialog={setShowFilterDialog}
                        updateQueryParams={updateQueryParams}
                        queryParams={queryParams}
                        setIsSearchOrFilterApplied={setIsSearchOrFilterApplied}
                    />
                    <Grid item container direction={"column"} spacing={4} padding={4} paddingRight='0px'>
                        {content}
                    </Grid>
                    {(responseData && (responseData.length > 0) && !showLoader) && (
                        <Grid container item xs={12} style={{ display: 'flex', justifyContent: 'center', marginBottom: '30px' }}>
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <span style={{ fontFamily: '"Helvetica Neue"', fontSize: '13px', color: '#3d3d3d' }}>
                                    {Math.min((parseInt(existingParams.page) - 1) * itemsPerPage + 1, totalCount)}-
                                    {Math.min(parseInt(existingParams.page) * itemsPerPage, totalCount)} of {totalCount}
                                </span>
                            </Grid>
                            <Pagination
                                page={parseInt(existingParams?.page) || 1}
                                count={totalPages}
                                variant="outlined"
                                shape="rounded"
                                color="primary"
                                onChange={handlePageChange}
                            />
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </div>
    )
}




