import { Button, CircularProgress, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { DeleteIcon } from '../Common/TableUI';
import { addNewComponent, deleteComponent, getComponentById, setStoreData, updateComponent } from '../../Redux/actions';
import { useDispatch } from 'react-redux';
import ConfirmDialog from '../Common/ConfirmDialog';
import { COMPONENTS_CATEGORIES } from '../../utils';

export default function ManageComponent () {

    let { id: id } = useParams()
    let navigate = useNavigate()
    const dispatch = useDispatch();
    const location = useLocation();
    const locationState = location?.state;
    const componentsQueryParams = localStorage.getItem('componentsQueryParams')
    const componentsCategories = COMPONENTS_CATEGORIES

    let initialForm = {
        componentName: '',
        componentId: '',
        category: '',
        manufacturer: '',
        specifications: '',
    }

    const [form, setForm] = useState(initialForm);
    const [itemNotFound, setItemNotFound] = useState(false);
    const error = {};
    const [errors, setErrors] = useState(error);
    const [disableButton, setDisableButton] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [showLoader, setShowLoader] = useState( id ? true : false )

    useEffect(() => {
        if (id) {
            dispatch(getComponentById(id)).then((resp) => {
                const response = resp?.data?.component
                if (!response) {
                    setItemNotFound(true);
                    setShowLoader(false)
                    return;
                }

                const { component_id, name, category, manufacturer, specifications} = response
                
                const existData = {
                    componentName: name,
                    componentId: component_id,
                    category: category,
                    manufacturer: manufacturer,
                    specifications: specifications,

                }
                setForm(existData)
                setShowLoader(false)
            })
        }
    }, []);

    const handleChange = (e, fieldName) => {
        const { value } = e.target;
        const data = (prevState) => {
            let newData = {
                ...prevState,
                [fieldName]: value
            };
            return newData;
        };
        const err = Object.assign({}, errors);
        if (err[fieldName]) {
            err[fieldName] = null;
        }
        setErrors(err);
        setForm(data);
        if (disableButton) {
            setDisableButton(false)
        }
    }

    const updateResponse = (resp, message) => {
        const res = resp && resp.data;
        let msg = res.message;
        let type = "fail";
        if (res && res.success) {
            msg = message;
            type = "success";
        }
        dispatch(setStoreData('alertMessage', {
            msg, type,
            handleClose: () => handleCloseDialog(),
        }))
    };

    const handleCloseDialog = () => {
        dispatch(setStoreData('alertMessage', null))
        componentsQueryParams ? navigate(`/components?${componentsQueryParams.toString()}`) : navigate(`/components`);
    }

    const validateData = () => {
        const err = { ...errors };
        let hasErrors = false;

        if (!form.componentName || form.componentName.trim() === '') {
            hasErrors = true;
            err.componentName = 'This field is mandatory';
        }

        if (!form.componentId || form.componentId.trim() === '') {
            hasErrors = true;
            err.componentId = 'This field is mandatory';
        }

        if (!form.category) {
            hasErrors = true;
            err.category = 'This field is mandatory';
        }

        if (!form.manufacturer || form.manufacturer.trim() === '') {
            hasErrors = true;
            err.manufacturer = 'This field is mandatory';
        }

        if (!form.specifications || form.specifications.trim() === '') {
            hasErrors = true;
            err.specifications = 'This field is mandatory';
        }

        if (hasErrors) {
            setErrors(err);
            return false;
        }
        return true;
    }

    const handleSubmit = async() => {
        setDisableButton(true)
        const checkForm = validateData();
        if ( checkForm ) {
            const data = {
                componentName: form.componentName,
                componentId: form.componentId,
                category: form.category,
                manufacturer: form.manufacturer,
                specifications: form.specifications,
            }

            if (id) {
                dispatch(updateComponent(data, id)).then((resp) => {
                    setDisableButton(false)
                    if (!resp) {
                        return;
                    } else {
                        updateResponse(resp, resp?.data?.message);
                    }
                });
            } else {
                dispatch(addNewComponent(data)).then((resp) => {
                    setDisableButton(false)
                    if (!resp) {
                        return;
                    } else {
                        updateResponse(resp, resp?.data?.message);
                    }
                });
            }
        }
    };

    const triggerDelete = () => {
        setSelectedItem(id);
        setShowConfirmDialog(true);
    }

    const handleDeleteProject = () => {
        if (selectedItem) {
            setShowConfirmDialog(false);
            dispatch(deleteComponent(selectedItem)).then((resp) => {
                updateResponse(resp, resp?.data?.message);
                setSelectedItem(null);
            })
        }
    }

    if (showLoader) {
        return <Grid container>
            <Grid style={{display: 'flex', justifyContent: 'center', width: '100vw', height: '500px',  alignItems: 'center' }}>
                <CircularProgress />
            </Grid>
        </Grid>
    }

    if (id && itemNotFound && !showLoader) {
        return <Grid container >
            <Grid item md={12} className='w3-padding' style={{ display: 'flex', justifyContent: 'center' }}>
                <h5 className='w3-text-grey'>
                    No Data Found
                </h5>
            </Grid>
        </Grid>
    }

    return (
        <Paper elevation={1} style={{ margin: '16px' }}>
            <Grid container className='w3-padding-small'>
                <Grid container >
                    <Grid item md={12} className='w3-padding' style={{ display: 'flex' }}>
                        <IconButton
                            onClick={() => {
                                componentsQueryParams ? 
                                navigate(`/components?${componentsQueryParams.toString()}`) :
                                navigate(`/components`)
                            }}
                            color="inherit">
                            <ArrowBackIosIcon fontSize='small'/>
                        </IconButton>
                        <h6>{id ? 'Edit Component' : 'Add New Component'}</h6>
                    </Grid>
                    <Grid container item md={12} style={{ display: 'flex', flexDirection: 'row', }}>
                        <Grid container item md={12} className='w3-padding' style={{ alignContent: 'flex-start' }}>
                            <Grid item md={12} style={{ display: 'flex', flexDirection: 'row', }} className='w3-margin-bottom'>
                                <TextField
                                    fullWidth
                                    label="Name"
                                    required
                                    value={form.componentName}
                                    onChange={(e) => handleChange(e, 'componentName')}
                                />
                            </Grid>
                            {errors.componentName && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.componentName}</div>}
                            <Grid item md={12} style={{ display: 'flex', flexDirection: 'row', }} className='w3-margin-bottom'>
                                <TextField
                                    fullWidth
                                    disabled={id}
                                    label="Component ID"
                                    required
                                    value={form.componentId}
                                    onChange={(e) => handleChange(e, 'componentId')}
                                />
                            </Grid>
                            {errors.componentId && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.componentId}</div>}
                            <Grid item md={12} style={{ display: 'flex', flexDirection: 'row', }} className='w3-margin-bottom'>
                                <FormControl fullWidth required>
                                    <InputLabel>Category</InputLabel>
                                        <Select
                                        label="Category"
                                        value={form.category}
                                        onChange={(e) => handleChange(e, 'category')}
                                        MenuProps={{
                                            disableScrollLock: true,
                                        }}
                                        >
                                             {componentsCategories.map((category, index) => (
                                            <MenuItem key={index} value={category}>
                                                {category}
                                            </MenuItem>
                                        ))} 
                                        </Select>
                                </FormControl>
                            </Grid>
                            {errors.category && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.category}</div>}
                            <Grid item md={12} style={{ display: 'flex', flexDirection: 'row', }} className='w3-margin-bottom'>
                                <TextField
                                    fullWidth
                                    required
                                    label="Manufacturer"
                                    value={form.manufacturer}
                                    onChange={(e) => handleChange(e, 'manufacturer')}
                                />
                            </Grid>
                            {errors.manufacturer && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.manufacturer}</div>}
                            <Grid item md={12} style={{ display: 'flex', flexDirection: 'row', }} className='w3-margin-bottom'>
                                <TextField
                                    multiline
                                    rows={4}
                                    fullWidth
                                    required
                                    label="Specifications"
                                    value={form.specifications}
                                    onChange={(e) => handleChange(e, 'specifications')}
                                />
                            </Grid>
                            {errors.specifications && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.specifications}</div>}
                        </Grid>
                    </Grid>
                    <Grid item md={12} style={{ display: 'flex', flexDirection: 'row' }}>
                        <Grid item md={6} style={{ display: 'flex', justifyContent: 'flex-start', paddingLeft: '16px' }} className='w3-margin-bottom'>
                            {id && (
                                <Button
                                    variant="outlined"
                                    startIcon={<DeleteIcon />}
                                    color="error"
                                    onClick={() => triggerDelete()}
                                    >
                                    Delete
                                    </Button>
                                )}
                        </Grid>
                        <Grid item md={12} style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '16px' }} className='w3-margin-bottom'>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    componentsQueryParams ?
                                    navigate(`/components?${componentsQueryParams.toString()}`) :
                                    navigate(`/components`)
                                }}
                            >
                                Cancel</Button>
                                <Button
                                    variant="contained"
                                    onClick={handleSubmit}
                                    style={{ marginLeft: '10px' }}
                                    disabled={disableButton}
                                >
                                    Submit</Button>
                        </Grid>
                    </Grid>
                    {showConfirmDialog
                        ? (
                            <ConfirmDialog
                                handleCancel={() => {
                                    setSelectedItem({})
                                    setShowConfirmDialog(false)
                                }}
                                handleOk={handleDeleteProject}
                                message={'Are you sure to delete this ?'}
                            />
                        ) : null}
                </Grid>
            </Grid>
        </Paper>
    )
}