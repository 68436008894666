
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getMeterCalibrationById, getMeterById, createNewCalibrationReport, deleteCalibrationReport, getMidsByFacilityId, deleteCalibrationReportImage } from '../../Redux/actions';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { TextField, FormControl, InputLabel, Select, MenuItem, Tooltip, ListItemText, Autocomplete, IconButton } from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { CircularProgress } from '@mui/material';

import { updateCalibrationReport } from '../../Redux/actions'

import AlertDialog from '../Common/AlertDialog';
import AddIcon from '@mui/icons-material/Add';

import CloseIcon from '@mui/icons-material/Close';

import { Can } from '../../Context/Can';
import { DeleteIcon } from '../Common/TableUI';
import ConfirmDialog from '../Common/ConfirmDialog';

import BackArrow from '../../Images/BackArrow.png';

import moment from 'moment';

import { isValidDate } from '../../utils/Common';
import { createFilterOptions } from '@mui/material/Autocomplete';


export default function CalibrationForm() {
    const { id, client_id, calibration_id, facilityId } = useParams();

    const intialForm = {
        sensor_name: '',
        meter_id: '',
        device_id: ''
    }

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [form, setForm] = useState(intialForm);

    const error = {};
    const [errors, setErrors] = useState(error);

    const [installation_date, setInstallationDate] = useState(null)
    const [latest_calibration_date, setLatestCalibrationDate] = useState(null)
    const [calibration_due_date, setCalibrationDueDate] = useState(null)
    const [last_working_date, setLastWorkingDate] = useState(null)

    const [showLoader, setShowLoader] = useState(false);

    const [selectedItem, setSelectedItem] = useState(null);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false)

    const [meterPid, setMeterPid] = useState()

    const [alertMessage, setAlertMessage] = useState();
    const [msgType, setMsgType] = useState("success");

    const [meterMid, setMeterMid] = useState();

    const [itemNotFound, setItemNotFound] = useState(false);
    const [facilityNotFound, setFacilityNotFound] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const [disableDueDate, setDisableDueDate] = useState(true);
    const [mids, setMids] = useState([]);

    const [newFieldsCount, setNewFieldsCount] = useState(0);
    const [dynamicFields, setDynamicFields] = useState([
        { cardId: 0, testedCharacteristics: '', methodChecking: '', result: '', images: [] }
    ]);
    const [uploadModalMessage, setUploadModalMessage] = useState();
    const [uploadedFile, setUploadedFile] = useState({});
    const [uploadedImage, setUploadedImage] = useState();
    const [showConfirmUploadDialog, setShowConfirmUploadDialog] = useState(false);
    const [selectedImages, setSelectedImages] = useState([]);

    const testedCharacteristicsItems = [
        { label: 'Probe Recognition', value: 'Probe Recognition' },
        { label: 'Physical Inspection', value: 'Physical Inspection' },
        { label: 'Burning Test', value: 'Burning Test' },
        { label: 'Ph Reading', value: 'Ph Reading' },
        { label: 'ORP Reading', value: 'ORP Reading' },
        { label: 'TDS Reading', value: 'TDS Reading' },
        { label: 'Response Time', value: 'Response Time' },
        { label: 'Inline Calibration for Turbine flow meters', value: 'Inline Calibration for Turbine flow meters' },
        { label: 'Zero Calibration for Electromagnetic flow meters', value: 'Zero Calibration for Electromagnetic flow meters' },
        { label: 'DO Reading', value: 'DO Reading' },
        { label: 'Turbidity Reading', value: 'Turbidity Reading' },
    ];

    const methodOfCheckingItems = [
        'Reference Solution',
        'Visual Inspection',
        'Meter kept for 15 days',
        'Standard buffer solution',
        'Standard 263 mV solution',
        'Standard buffer solution of 4 pH',
        'Standard buffer solution of 7 pH',
        'Visual Inspection - 0 - 20 sec',
        'Factory Calibration',
        'Collect and measure the water passed using a standard measuring device',
        'Checking sensor electronic Functionality',
        'Checking system damage or changes that caused potential calibration drift'
    ]
    const [filteredMethodCheckingItems, setFilteredMethodCheckingItems] = useState(methodOfCheckingItems);
    const [actualMethodCheckingItem, setActualMethodCheckingItem] = useState([]);
    const resultItem = [
        { label: 'Pass', value: 'pass' },
        { label: 'Fail', value: 'fail' }
    ]

    const testedCharacteristicsMethodMapping = {
        'Ph Reading': ['Standard buffer solution of 4 pH', 'Standard buffer solution of 7 pH'],
        'Probe Recognition': ['Reference Solution'],
        'Physical Inspection': ['Visual Inspection'],
        'Burning Test': ['Meter kept for 15 days'],
        'Response Time': ['Visual Inspection - 0 - 20 sec'],
        'ORP Reading': ['Standard 263 mV solution'],
        'TDS Reading': ['Reference Solution'],
        'Turbidity Reading': ['Reference Solution'],
        'DO Reading': ['Reference Solution'],
        'Inline Calibration for Turbine flow meters': ['Visual Inspection'],
        'Zero Calibration for Electromagnetic flow meters': ['Visual Inspection', 'Factory Calibration', 'Collect and measure the water passed using a standard measuring device',
        'Checking sensor electronic Functionality', 'Checking system damage or changes that caused potential calibration drift'],
    };

    useEffect(() => {
        setShowLoader(true)
        if (calibration_id) {
            dispatch(getMeterCalibrationById(client_id, calibration_id)).then(resp => {
                const response = resp?.data?.data
                if (!response) {
                    setItemNotFound(true);
                    return
                }

                const { calibration_due_date, editedTime, allCalibratedDates, installation_date, last_working_date, latest_calibration_date, meterid, methodChecking, result, sensor_id, testedCharacteristics, workingStatus, meter, pid, dynamicFieldsData, media } = response;

                if (installation_date) {
                    setInstallationDate(installation_date);
                }
                if (latest_calibration_date) {
                    setLatestCalibrationDate(latest_calibration_date);
                }
                if (calibration_due_date) {
                    setCalibrationDueDate(calibration_due_date);
                }
                if (last_working_date) {
                    setLastWorkingDate(last_working_date);
                }

                if (pid) {
                    setMeterPid(pid)
                }

                if ( meter ){
                    setMeterMid(meter?.mid)
                }

                const data = {
                    calibration_due_date,
                    editedTime,
                    allCalibratedDates: allCalibratedDates || moment(latest_calibration_date).format("YYYY-MM-DD"),
                    installation_date,
                    last_working_date,
                    latest_calibration_date,
                    meterid,
                    methodChecking,
                    result,
                    sensor_id,
                    testedCharacteristics,
                    working_status: workingStatus,
                    sensor_name: meter.metername,
                    meter_id: meter.meterid,
                    device_id: sensor_id,
                    media
                }
                if(latest_calibration_date) {
                    setDisableDueDate(false)
                }
                if (methodChecking && result && testedCharacteristics) {
                    const methodCheckingArray = methodChecking.split('<br>');
                    const resultArray = result.split('<br>');
                    const testedCharacteristicsArray = testedCharacteristics.split('<br>');
                    const maxCount = Math.max(
                        methodCheckingArray.length,
                        resultArray.length,
                        testedCharacteristicsArray.length
                    );

                    setNewFieldsCount(maxCount);

                    const dynamicFieldsData = [];
                    const actualMethodItems = [];

                    let updatedFilteredMethodCheckingItems = [...filteredMethodCheckingItems];
                    for (let i = 0; i < maxCount; i++) {
                        dynamicFieldsData.push({
                            cardId: i,
                            methodChecking: methodCheckingArray[i] || '',
                            result: resultArray[i] || '',
                            testedCharacteristics: testedCharacteristicsArray[i] || ''
                        });
                        actualMethodItems.push(methodCheckingArray[i]);
                        if (testedCharacteristicsArray[i]) {
                            const relevantMethods = testedCharacteristicsMethodMapping[testedCharacteristicsArray[i]] || [];
                            updatedFilteredMethodCheckingItems[i] = relevantMethods;
                        }
                    }
                    setFilteredMethodCheckingItems(updatedFilteredMethodCheckingItems);
                    setActualMethodCheckingItem(actualMethodItems)
                    
                    setDynamicFields(dynamicFieldsData);
                }

                if (dynamicFieldsData) {
                    const newDataArray = dynamicFieldsData?.map((dfield, index) => {
                        const imageArr = [];
                        for (const dImages of dfield.images) {
                            imageArr.push({
                                url: dImages?.url ? dImages?.url : dImages?.url?.url,
                                signedUrl: dImages?.signedUrl
                            });
                        }
                    
                        const data = {
                            cardId: index,
                            testedCharacteristics: dfield.testedCharacteristics,
                            methodChecking: dfield.methodChecking,
                            result: dfield.result,
                            images: imageArr
                        };
                        return data;
                    });
                    
                    
                    const newDynamicFields = dynamicFieldsData?.map((field, idx) => ({
                        cardId: idx,
                        testedCharacteristics: field.testedCharacteristics,
                        methodChecking: field.methodChecking,
                        result: field.result,
                        images: field?.images?.map(image => ({
                            url: image?.url,
                            signedUrl:  image?.signedUrl
                        }))
                    }));
                    setDynamicFields(newDataArray);
                    setNewFieldsCount(newDynamicFields.length);
                }
                setForm(data);
            })
        }
        if (id) {
            dispatch(getMeterById(client_id, id)).then(resp => {
                const meterData = resp?.data?.meterData
                if (!meterData) {
                    setItemNotFound(true);
                    return
                }
                setMeterMid(meterData?.mid)
                if (meterData?.pid) {
                    setMeterPid(meterData.pid)
                } else if (meterData?.project_id) {
                    setMeterPid(meterData.project_id)
                }
                const data = {
                    meter_id: meterData.meterid,
                    sensor_name: meterData.metername,
                }
                if (meterData?.commission_tm) {
                    const installedDate = new Date(meterData.commission_tm);
                    setInstallationDate(installedDate);
                }
                setForm(data);
                
            })
        }
        if( facilityId ) {
            dispatch(getMidsByFacilityId(client_id, facilityId)).then(resp => {
                setMids(resp?.data?.midData)
                if(resp?.data?.facilityNotFound) {
                    setFacilityNotFound(true);
                }
            })
        }
        setShowLoader(false);
    }, [id, client_id, calibration_id])
    
    const handleSelect = (e) => {
        if(disableButton) {
            setDisableButton(false)
        }
        const { name, value } = e.target;
        const data = Object.assign({}, form);
        data[name] = value;
        const err = Object.assign({}, errors);
        if (err[name]) {
            err[name] = null;
        }
        setErrors(err);
        setForm(data)

    }
    const handleChange = (e) => {
        if(disableButton) {
            setDisableButton(false)
        }
        const { name, value } = e.target;
        const data = Object.assign({}, form);
        data[name] = value;
        const err = Object.assign({}, errors);
        if (err[name]) {
            err[name] = null;
        }
        setErrors(err);
        setForm(data);

    }

    const triggerDelete = () => {
        setSelectedItem(calibration_id);
        setShowConfirmDialog(true);
    }

    const handleDeleteCalibrationReport = () => {
        if (selectedItem) {
            setShowConfirmDialog(false);
            dispatch(deleteCalibrationReport(client_id, meterPid, selectedItem)).then((resp) => {
                setSelectedItem(null);
                if (resp?.data?.success) {
                    setAlertMessage(resp.data.message)
                    setMsgType('success')
                } else {
                    setAlertMessage(resp.data.message)
                    setMsgType('fail')
                }
            })
        }
    }

    const validateData = () => {
        const err = { ...errors };
        let hasErrors = false;

        if (!form.sensor_name) {
            hasErrors = true;
            err.sensor_name = 'This field is mandatory';
        }

        if (facilityId && !form.meter_id) {
            hasErrors = true;
            err.meter_id = 'This field is mandatory';
        }

        const dueDate = new Date(calibration_due_date);
        if (dueDate){ dueDate.setHours(0, 0, 0, 0);}
        const latestCalibDate = latest_calibration_date ? new Date(latest_calibration_date) : null;
        if (latestCalibDate) {latestCalibDate.setHours(0, 0, 0, 0);}
        const installationDate = installation_date ? new Date(installation_date) : null;
        if (installationDate) {installationDate.setHours(0, 0, 0, 0);}
        const lastWorkingDate = last_working_date ? new Date(last_working_date) : null;
        if(lastWorkingDate) {lastWorkingDate.setHours(0, 0, 0, 0);}
        const today = new Date();
        today.setHours(0, 0, 0, 0); 

        if (!form.device_id || form.device_id.trim() === "") {
            hasErrors = true;
            err.device_id = 'This field is mandatory';
        }

        if (id && !installation_date) {
            hasErrors = true;
            err.installation_date = 'This field is mandatory';
        }

        if (!latest_calibration_date) {
            hasErrors = true;
            err.latest_calibration_date = 'This field is mandatory';
        } else if (!isValidDate(latest_calibration_date)) {
            hasErrors = true;
            err.latest_calibration_date = 'Please enter a valid date in the format DD/MM/YYYY';
        } else if (latest_calibration_date) {
            if(latestCalibDate < installationDate) {
                hasErrors = true;
               err.latest_calibration_date = 'Latest date of calibration should be greater than or equal to date of installation';
            }

            if(latestCalibDate > today) {
                hasErrors = true;
                err.latest_calibration_date = "Latest date of calibration should be less than or equal to today's date";
            }
        }

        if (!calibration_due_date) {
            hasErrors = true;
            err.calibration_due_date = 'This field is mandatory';
        } else if (!isValidDate(calibration_due_date)) {
            hasErrors = true;
            err.calibration_due_date = 'Please enter a valid date in the format DD/MM/YYYY';
        } else if (calibration_due_date) {
            if (dueDate < latestCalibDate) {
                hasErrors = true;
                err.latest_and_due_date = 'Due date of calibration should be greater than or equal to latest date of calibration.';
            } 
            if (dueDate < installationDate) {
                hasErrors = true;
                err.calibration_due_date = 'Due date of calibration should be greater than or equal to date of installation';
            } 
            
        }

        if (!form.working_status) {
            hasErrors = true;
            err.working_status = 'This field is mandatory';
        }

        if (form.working_status === 'no') {
            if (!last_working_date) {
                hasErrors = true;
                err.last_working_date = 'This field is mandatory';
            } else if (!isValidDate(last_working_date)) {
                hasErrors = true;
                err.last_working_date = 'Please enter a valid date in the format DD/MM/YYYY';
            } else if (lastWorkingDate) {
                if(lastWorkingDate < installationDate) {
                    hasErrors = true;
                    err.last_working_date = 'Last date of working should be greater than or equal to date of installation';
                }
                if(lastWorkingDate > today) {
                    hasErrors = true;
                    err.last_working_date = "Last date of working should be less than or equal to today's date";
                }
            }
        }

        dynamicFields.forEach((field, index) => {
            if (!field.testedCharacteristics) {
                hasErrors = true;
                err[`testedCharacteristics${index}`] = 'This field is mandatory';
            }
            if (!field.methodChecking) {
                hasErrors = true;
                err[`methodChecking${index}`] = 'This field is mandatory';
            }
            if (!field.result) {
                hasErrors = true;
                err[`result${index}`] = 'This field is mandatory';
            }
            const methods = testedCharacteristicsMethodMapping[field.testedCharacteristics];
            if (methods && field.methodChecking) {
                if (!methods.includes(field.methodChecking)) {
                    hasErrors = true;
                    err[`methodChecking${index}`] = 'Method Checking does not exist in selected Tested Characteristics';
                }
            }
            if ( field?.images?.length > 5 ) {
                setUploadModalMessage('Maximum upload limit of 5 reached for this test characteristics');
                setMsgType('fail');
                hasErrors = true;
            }
        });

        const dynamicFieldCombinations = dynamicFields.map((field) => `${field.testedCharacteristics}-${field.methodChecking}`);

        dynamicFieldCombinations.forEach((combination, index) => {
            const duplicateIndex = dynamicFieldCombinations.indexOf(combination);
            if (duplicateIndex !== index) {
                err[`duplicateCombination${index}`] = 'Duplicate combination for tested characteristics and method checking';
                hasErrors = true;
            }
        });


        if (hasErrors) {
            setErrors(err);
            return false;
        }
        return true;
    };

    const handleSubmit = () => {
        setDisableButton(true);
        const checkForm = validateData();
        if (checkForm) {
            const testedCharacteristics = dynamicFields?.map((field, index) => `${field.testedCharacteristics}`).join('<br>');
            const methodChecking = dynamicFields?.map((field, index) => `${field.methodChecking}`).join('<br>');
            const result = dynamicFields?.map((field, index) => `${field.result}`).join('<br>');

            const dynamicData = dynamicFields?.map((field, index) => ({
                cardId: field.cardId,
                testedCharacteristics: field.testedCharacteristics,
                methodChecking: field.methodChecking,
                result: field.result,
                images: field?.images?.map((image, idx) => (
                    image.url
                ))
            }));

            const latestCalibDate = new Date(latest_calibration_date);
            const formattedLatestCalibDate = moment(latestCalibDate).format("YYYY-MM-DD");
            const existingDatesArray = form.allCalibratedDates?.split(',').map(date => date.trim()).filter(date => date !== '') || [];
            if (!existingDatesArray?.some(date => moment(date).isSame(formattedLatestCalibDate, 'day'))) {
                existingDatesArray.push(formattedLatestCalibDate);
            }

            const installationDateObject = new Date(installation_date);

            const year = installationDateObject.getFullYear();
            const month = (installationDateObject.getMonth() + 1).toString().padStart(2, '0');
            const day = installationDateObject.getDate().toString().padStart(2, '0');

            const formattedInstallationDate = `${year}-${month}-${day}`;
            const data = {
                // sensor_name: form.sensor_name,
                meter_id: form.meter_id,
                sensor_id: form.device_id,
                installation_date: formattedInstallationDate,
                latest_calibration_date,
                calibration_due_date,
                working_status: form.working_status,
                testedCharacteristics,
                methodChecking,
                result,
                allCalibratedDates: existingDatesArray.join(', '),
                client_id,
                last_working_date,
                mid: meterMid,
                dynamicFieldsData: dynamicData
            }

            const formData = new FormData();
            formData.append('data', JSON.stringify(data))
            
            const cardIndexMap = {};
            
            Object.values(uploadedFile).forEach((files, index) => {
                files.forEach((file, idx) => {
                    formData.append(`imageFiles`, file);

                    if (!cardIndexMap[index]) {
                        formData.append(`cardIndex[${index}][${idx}]`, index);
                        cardIndexMap[index] = true;
                    }

                    formData.append(`imageIndex[${index}][${idx}]`, idx);
                });
            });

            Object.keys(uploadedFile).forEach(index => {
                const dynamicIndex = parseInt(index);
                if (!isNaN(dynamicIndex) && dynamicFields[dynamicIndex]) {
                    if (!dynamicData[dynamicIndex].images) {
                        dynamicData[dynamicIndex].images = [];
                    }
                    uploadedFile[index].forEach(file => {
                        dynamicData[dynamicIndex].images.push({
                            url: URL.createObjectURL(file),
                            signedUrl: null 
                        });
                    });
                }
            });

            if (calibration_id) {
                dispatch(updateCalibrationReport(calibration_id, formData)).then(resp => {
                    if (resp?.data?.success) {
                        setAlertMessage(resp.data.message)
                        setMsgType('success')
                    } else {
                        setAlertMessage(resp.data.message)
                        setMsgType('fail')
                    }
                })
            } else if (id || facilityId ) {
                dispatch(createNewCalibrationReport(formData)).then(resp => {
                    if (resp?.data?.success) {
                        setAlertMessage(resp.data.message)
                        setMsgType('success')
                    } else {
                        setAlertMessage(resp.data.message)
                        setMsgType('fail')
                    }
                })
            }

            if(calibration_id && selectedImages?.length > 0) {
                dispatch(deleteCalibrationReportImage(selectedImages, client_id, calibration_id))
            }
        }
    }

    const handleAddField = () => {
        setNewFieldsCount(newFieldsCount + 1);
        const nextCardId = dynamicFields[dynamicFields.length - 1].cardId;
        setDynamicFields([...dynamicFields, { cardId: nextCardId + 1,testedCharacteristics: '', methodChecking: '', result: '', images: [] }]);
        if(disableButton) {
            setDisableButton(false)
        }
    };

    const handleDynamicFieldChange = (index, fieldName, value) => {
        if(disableButton) {
            setDisableButton(false)
        }
        const updatedFields = [...dynamicFields];
        updatedFields[index][fieldName] = value;
        setDynamicFields(updatedFields);

        const err = Object.assign({}, errors);
        const selectedField = `${fieldName}${index}`
        if (err[selectedField]) {
            err[selectedField] = null;
        }
        const duplicateCombinationErr = `duplicateCombination${index}`
        if (err[duplicateCombinationErr]) {
            err[duplicateCombinationErr] = null;
        }
        setErrors(err);

        if (fieldName === 'testedCharacteristics') {
            const relevantMethods = testedCharacteristicsMethodMapping[value] || [];
            let updatedFilteredMethodCheckingItems = [...filteredMethodCheckingItems];
            updatedFilteredMethodCheckingItems[index] = relevantMethods;
            setFilteredMethodCheckingItems(updatedFilteredMethodCheckingItems);
            updatedFields[index]['methodChecking'] = '';
            setDynamicFields(updatedFields);
            let updatedActualMethodCheckingItem = [...actualMethodCheckingItem];
            updatedActualMethodCheckingItem[index] = '';
            setActualMethodCheckingItem(updatedActualMethodCheckingItem);
        }
    };


    const handleRemoveField = (indexToRemove) => {
        if (newFieldsCount > 0 && dynamicFields.length > 1) {
            const updatedUploadedFile = { ...uploadedFile };
            delete updatedUploadedFile[indexToRemove];

            const reIndexedUploadedFile = { ...updatedUploadedFile };
            Object.keys(updatedUploadedFile).forEach((key) => {
                const numericKey = parseInt(key);
                if (numericKey >= indexToRemove + 1) {
                    const fileArray = updatedUploadedFile[key].map((file) => {
                        const parts = file.name.split('_');
                        parts[1] = numericKey - 1; 
                        const newFile = new File([file], parts.join('_'), { type: file.type });
                        return newFile;
                    });
                    reIndexedUploadedFile[numericKey - 1] = fileArray; 
                    delete reIndexedUploadedFile[key]; 
                }
            });
            setUploadedFile(reIndexedUploadedFile);

            const updatedDynamicData = dynamicFields?.filter((item, index) => index !== indexToRemove);
            const reIndexedDynamicData = updatedDynamicData.map((item, newIndex) => ({
                ...item,
                cardId: newIndex,
            }));

            const updatedMedia = form?.media?.filter(mediaItem => {
                const fileNameParts = mediaItem.file_name.split('_');
                const imageIndex = parseInt(fileNameParts[1]);
                return imageIndex !== indexToRemove;
            });
            
            const updatedFilteredMethodCheckingItems = [...filteredMethodCheckingItems];
            updatedFilteredMethodCheckingItems.splice(indexToRemove, 1);
            setFilteredMethodCheckingItems(updatedFilteredMethodCheckingItems);

            const err = { ...errors };
            if(err[`duplicateCombination${indexToRemove}`]) {
                err[`duplicateCombination${indexToRemove}`] = null;
            }
            if(err[`testedCharacteristics${indexToRemove}`]) {
                err[`testedCharacteristics${indexToRemove}`] = null;
            }
            if(err[`methodChecking${indexToRemove}`]) {
                err[`methodChecking${indexToRemove}`] = null;
            }
            if(err[`result${indexToRemove}`]) {
                err[`result${indexToRemove}`] = null;
            }
            setErrors(err);
            
            setForm(prevForm => ({
                ...prevForm,
                media: updatedMedia
            }));
            
            setDynamicFields(reIndexedDynamicData);
            setNewFieldsCount(newFieldsCount - 1);
            if(disableButton) {
                setDisableButton(false)
            }
        }
    };

    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        stringify: (option) => `${option?.metername} (${option?.meterid})`,
    });

    const handleMeterChange = (event, newValue) => {
        const data = { ...form };
        data.meter_id = newValue ? newValue.meterid : ''; 
        data.sensor_name = newValue ? newValue.metername : '';
        setForm(data);
        setMeterMid(newValue?.mid)
        const installedDate = new Date(newValue ? newValue.commission_tm : '');
        setInstallationDate(installedDate);
        const err = Object.assign({}, errors);
        if (err['meter_id']) {
            err['meter_id'] = null;
        }
        if (err['sensor_name']) {
            err['sensor_name'] = null;
        }
        setErrors(err);
        if(disableButton) {
            setDisableButton(false)
        }
    };

    const handleFileChange = (event, fieldIndex) => {
        if (event.target.files.length) {
            const files = event.target.files;
            let fileList = Array.from(files);

            const imageType = ['image/jpeg', 'image/png', 'image/jpg', 'image/heif']
            const invalidFiles = fileList.filter(file => !imageType.includes(file.type));
        if (invalidFiles.length > 0) {
            setUploadModalMessage('Unsupported file type. Please upload only image files.');
            setMsgType('fail');
            return;
        }

            const currentUploadedFiles = uploadedFile[fieldIndex]?.length || 0;
            const existingImagesCount = dynamicFields[fieldIndex]?.images?.length || 0;
            
            const totalFilesCount = currentUploadedFiles + (existingImagesCount ? existingImagesCount : 0);
            const remainingSlots = 5 - totalFilesCount;
            const currentField = dynamicFields[fieldIndex];

            if ((currentUploadedFiles + fileList?.length > 5) || (currentField?.images?.length + currentUploadedFiles + fileList?.length > 5)) {
                setUploadModalMessage('Maximum upload limit of 5 reached for this test characteristics');
                setMsgType('fail');
                fileList = fileList.slice(0, remainingSlots);
            }

            const imageNumber = currentUploadedFiles + fileList.length;

            const updatedFiles = fileList.map((file, index) => {
                const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
                let randomString = '';
                const charactersLength = characters?.length;

                for (let i = 0; i < 4; i++) {
                    randomString += characters.charAt(Math.floor(Math.random() * charactersLength));
                }
                const newFileName = `card_${fieldIndex}_${imageNumber + index}_${randomString}_${file.name}`;
                return new File([file], newFileName, { type: file.type });
            });

            const updatedUploadedFile = {
                ...uploadedFile,
                [fieldIndex]: uploadedFile[fieldIndex] ? [...uploadedFile[fieldIndex], ...updatedFiles] : updatedFiles
            };

            setUploadedFile(updatedUploadedFile);
        }
        event.target.value = ''
    };

    const handleDeleteImage = (fieldIndex, imageIndex) => {
        const updatedUploadedFile = { ...uploadedFile };
        updatedUploadedFile[fieldIndex] = updatedUploadedFile[fieldIndex]?.filter((_, idx) => idx !== imageIndex);
        setUploadedFile(updatedUploadedFile);
    };

    const handleDeleteStoredImage = (mediaItemId) => {
        const updatedDynamicFields = dynamicFields?.map(field => {
            const updatedImages = field.images?.filter(image => image.url !== mediaItemId.url);
            return {
                ...field,
                images: updatedImages
            };
        });

        const updatedMedia = form?.media?.filter(mediaItem => {
            return mediaItem.path !== mediaItemId.url;
        });
        setSelectedImages([...selectedImages, mediaItemId.url])
        setDynamicFields(updatedDynamicFields);
        setForm(prevForm => ({
            ...prevForm,
            media: updatedMedia
        }));
        setDisableButton(false)
    };

    if (showLoader) {
        return <div className="w3-center">
            <CircularProgress />
        </div>
    }

    if (calibration_id && itemNotFound || itemNotFound) {
        return <Grid container >
            <Grid item md={12} className='w3-padding' style={{ display: 'flex', justifyContent: 'center', height: '500px', alignItems: 'center' }}>
                <h5 className='w3-text-grey'>
                    Calibration report not found.
                </h5>
            </Grid>
        </Grid>

    }
    if(facilityNotFound) {
        return <Grid container >
        <Grid item md={12} className='w3-padding' style={{ display: 'flex', justifyContent: 'center', height: '500px', alignItems: 'center' }}>
            <h5 className='w3-text-grey'>
                Calibration report not found
            </h5>
        </Grid>
    </Grid> 
    }
    return (<Paper elevation={1} style={{ margin: '16px ' }}>
        <Grid container >
            <Grid container className='w3-padding'>
                <Grid style={{ display: 'flex' }}>
                    <img id="back-arrow" className="cursor-pointer" width="20px" height="20px" onClick={() => navigate(-1)} src={BackArrow} alt="back-arrow" style={{ marginTop: '10px', marginRight: '5px' }} />
                    <Grid item md={12}>
                        <h6 style={{ paddingBottom: '8px' }}>{(facilityId && calibration_id || calibration_id) ? 'Edit Calibration Report' : 'Create New Calibration Report'}</h6>
                    </Grid>
                </Grid>
                <Grid container item md={12}>
                {(calibration_id || id) && (
                    <Grid item md={12} className="w3-margin-bottom" style={{ display: 'flex', flexDirection: 'row' }}>
                        <TextField
                        fullWidth
                        label="Sensor Name"
                        name="sensor_name"
                        required
                        disabled
                        value={form.sensor_name}
                        onChange={handleChange}
                        />
                        {errors.sensor_name && (
                        <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>
                            {errors.sensor_name}
                        </div>
                        )}
                    </Grid>
                    )}
                    <Grid item md={12} className="w3-margin-bottom" style={{ display: 'flex', flexDirection: 'row', }}>
                        { (facilityId && !calibration_id) ? 
                            (<Autocomplete
                                fullWidth
                                required
                                options={mids}
                                filterOptions={filterOptions}
                                getOptionLabel={(option) => `${option?.metername} - ${option?.meterid} (MID:${option?.mid})`}
                                value={mids?.find((meter) => meter?.meterid === form.meter_id) || null}
                                onChange={handleMeterChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Meter Id"
                                        name="meter_id"
                                        required
                                    />
                                )}
                            />) :
                            (<TextField
                                fullWidth
                                label="Meter Id"
                                name="meter_id"
                                disabled
                                required
                                value={form.meter_id}
                                onChange={handleChange} />)}
                    </Grid>
                    {errors.meter_id && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.meter_id}</div>}

                    <Grid item md={12} className="w3-margin-bottom" style={{ display: 'flex', flexDirection: 'row', }}>
                        <TextField
                            fullWidth
                            label="Device Id"
                            name="device_id"
                            required
                            value={form.device_id}
                            onChange={handleChange} />
                    </Grid>
                    {errors.device_id && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.device_id}</div>}

                    <Grid item md={12}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
                            <DatePicker
                                label="Date of Installation*"
                                dateFormat="DD/MM/YYYY"
                                format="DD/MM/YYYY"
                                required
                                disabled={true}
                                slotProps={{ textField: { fullWidth: true, error: false, } }}
                                name="installation_date"
                                value={installation_date ? dayjs(installation_date) : null}
                                onChange={(newValue) => {
                                    setInstallationDate(newValue)
                                    setErrors({ ...errors, installation_date: null });
                                    setDisableButton(false);
                                }}
                            />
                        </LocalizationProvider>
                        {errors.installation_date && <div className='error-text w3-margin-bottom'>{errors.installation_date}</div>}
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item md={6} sx={{ mt: 2 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
                                <DatePicker
                                    label="Latest date of Calibration*"
                                    dateFormat="DD/MM/YYYY"
                                    format="DD/MM/YYYY"
                                    required
                                    minDate={installation_date ? dayjs(installation_date) : undefined}
                                    maxDate={calibration_due_date && (new Date(calibration_due_date) < new Date())   ? dayjs(calibration_due_date) : dayjs()}
                                    slotProps={{ textField: { fullWidth: true, error: false, } }}
                                    name="latest_calibration_date"
                                    value={latest_calibration_date ? dayjs(latest_calibration_date) : null}
                                    onChange={(newValue) => {
                                        setLatestCalibrationDate(newValue)
                                        setErrors({ ...errors, latest_calibration_date: null, latest_and_due_date: null });
                                        setDisableButton(false);
                                        if(isValidDate(newValue)) {
                                            setDisableDueDate(false);
                                        } else {
                                            setDisableDueDate(true);
                                        }
                                        if(!newValue) {
                                            setDisableDueDate(true);
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>

                        <Grid item md={6} sx={{ mt: 2 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
                                <DatePicker
                                    label="Due date of Calibration*"
                                    dateFormat="DD/MM/YYYY"
                                    format="DD/MM/YYYY"
                                    required
                                    disabled={disableDueDate}
                                    minDate={latest_calibration_date ? dayjs(latest_calibration_date) : installation_date ? dayjs(installation_date) : undefined}
                                    slotProps={{ textField: { fullWidth: true, error: false, } }}
                                    name="calibration_due_date"
                                    value={calibration_due_date ? dayjs(calibration_due_date) : null}
                                    onChange={(newValue) => {
                                        setCalibrationDueDate(newValue)
                                        setErrors({ ...errors, calibration_due_date: null, latest_and_due_date: null });
                                        setDisableButton(false);
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item md={6}>
                            {errors.latest_calibration_date && <div className='error-text'>{errors.latest_calibration_date}</div>}
                        </Grid>
                        <Grid item md={6}>
                            {errors.calibration_due_date && <div className='error-text'>{errors.calibration_due_date}</div>}
                            {errors.latest_and_due_date && <div className='error-text'>{errors.latest_and_due_date}</div>}
                        </Grid>
                    </Grid>
                    <Grid item md={12} sx={{ mt: 2 }}>
                        <FormControl sx={{ width: '100%' }}>
                            <InputLabel id="demo-multiple-name-label">Working Status *</InputLabel>
                            <Select
                                value={form.working_status || ''}
                                name="working_status"
                                label="Working Status"
                                required
                                fullWidth
                                onChange={handleSelect}
                                MenuProps={{
                                    disableScrollLock: true,
                                }}
                            >
                                <MenuItem value="yes">Yes</MenuItem>
                                <MenuItem value="no">No</MenuItem>

                            </Select>
                            {errors.working_status && <div className='error-text w3-margin-bottom'>{errors.working_status}</div>}
                        </FormControl>

                    </Grid>

                    {form.working_status === 'no' && <Grid item md={12} sx={{ mt: 2 }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
                            <DatePicker
                                label="Last Date Of Working*"
                                dateFormat="DD/MM/YYYY"
                                format="DD/MM/YYYY"
                                required
                                minDate={installation_date ? dayjs(installation_date) : undefined}
                                maxDate={dayjs()}
                                slotProps={{ textField: { fullWidth: true, error: false, } }}
                                name="last_working_date"
                                value={last_working_date ? dayjs(last_working_date) : null}
                                onChange={(newValue) => {
                                    setLastWorkingDate(newValue)
                                    setErrors({ ...errors, last_working_date: null });
                                    setDisableButton(false);
                                }}
                            />
                        </LocalizationProvider>
                        {errors.last_working_date && <div className='error-text w3-margin-bottom'>{errors.last_working_date}</div>}
                    </Grid>}

                    {calibration_id && <Grid item md={12} sx={{ mt: 2 }}>
                        <TextField
                            fullWidth
                            name="allCalibratedDates"
                            value={form.allCalibratedDates || ''}
                            label="All Calibrated Dates"
                            multiline
                            rows={3}
                            onChange={handleChange}
                            placeholder="All Calibrated Dates"
                        />
                    </Grid>}

                    <Grid item xs={12}>
                        {dynamicFields.map((field, index) => {
                           return <Paper square={false} elevation={4} key={index} sx={{ mt: 2 }} className="w3-padding">
                                {dynamicFields?.length > 1 && (
                                    <Tooltip title="Remove" className='w3-right' style={{ position: 'absolute', top: '8px', right: '6px' }}>
                                        <Grid item style={{ display: 'flex', justifyContent: "flex-end" }} className="cursor-pointer font-size-12 error-text" onClick={() => handleRemoveField(field.cardId)}>
                                            <CloseIcon name="remove" />
                                        </Grid>
                                    </Tooltip>
                                )}
                                <Grid item xs={12} sx={{ mt: 4 }}>
                                    <FormControl fullWidth required>
                                        <InputLabel id={`testedCharacteristics-label-${index}`}>Tested Characteristics</InputLabel>
                                        <Select
                                            labelId={`testedCharacteristics-label-${index}`}
                                            id={`testedCharacteristics-${index}`}
                                            value={field.testedCharacteristics}
                                            label={`Tested Characteristics`}
                                            name={`testedCharacteristics${index}`}
                                            onChange={(e) => handleDynamicFieldChange(index, 'testedCharacteristics', e.target.value)}
                                            MenuProps={{
                                                disableScrollLock: true,
                                            }}
                                        >
                                            {testedCharacteristicsItems.map((item, i) => (
                                                <MenuItem key={i} value={item.value}>
                                                    {item.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    {errors[`testedCharacteristics${index}`] && <div className='error-text w3-margin-bottom'>{errors[`testedCharacteristics${index}`]}</div>}
                                </Grid>
                                <Grid item xs={12} sx={{ mt: 2 }}>
                                    <FormControl fullWidth required>
                                        <InputLabel id={`methodChecking-label-${index}`}>Method Checking</InputLabel>
                                        <Select
                                            labelId={`methodChecking-label-${index}`}
                                            id={`methodChecking-${index}`}
                                            value={field.methodChecking}
                                            disabled={!field.testedCharacteristics}
                                            label={`Method Checking`}
                                            name={`methodChecking${index}`}
                                            onChange={(e) => handleDynamicFieldChange(index, 'methodChecking', e.target.value)}
                                            MenuProps={{
                                                disableScrollLock: true,
                                            }}
                                       >
                                           {actualMethodCheckingItem[index] && (
                                               <MenuItem value={actualMethodCheckingItem[index]} style={{ display: 'none' }}>
                                               </MenuItem>
                                           )}

                                           {Array.isArray(filteredMethodCheckingItems[index]) ? filteredMethodCheckingItems[index].map((item, i) => (

                                               <MenuItem key={i} value={item}>
                                                   {item}
                                               </MenuItem>
                                           )) : filteredMethodCheckingItems.map((item, i) => (
                                               <MenuItem key={i} value={item}>
                                                   {item}
                                               </MenuItem>
                                           ))}
                                           </Select>
                                   </FormControl>
                                   {errors[`methodChecking${index}`] && <div className='error-text w3-margin-bottom'>{errors[`methodChecking${index}`]}</div>}
                               </Grid>
                               <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                                    <FormControl fullWidth required>
                                        <InputLabel id={`result-label-${index}`}>Result</InputLabel>
                                        <Select
                                            labelId={`result-label-${index}`}
                                            id={`result-${index}`}
                                            value={field.result}
                                            label={`Result`}
                                            name={`result${index}`}
                                            onChange={(e) => handleDynamicFieldChange(index, 'result', e.target.value)}
                                            MenuProps={{
                                                disableScrollLock: true,
                                            }}
                                        >
                                            {resultItem.map((item, i) => (
                                                <MenuItem key={i} value={item.value}>
                                                    {item.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    {errors[`result${index}`] && <div className='error-text w3-margin-bottom'>{errors[`result${index}`]}</div>}
                                    {errors[`duplicateCombination${index}`] && <div className='error-text w3-margin-bottom'>{errors[`duplicateCombination${index}`]}</div>}
                                </Grid>
                                <Grid container item md={12} className='w3-margin-bottom'>
                                    <label htmlFor={`logo-file-${index}`}>
                                        <input
                                            style={{ display: 'none' }}
                                            required
                                            id={`logo-file-${index}`}
                                            name={`logoFile-${index}`}
                                            accept="image/jpeg, image/png, image/, 'image/heif'"
                                            type="file"
                                            multiple
                                            onChange={(event) => handleFileChange(event, field.cardId)}
                                        />
                                        <div style={{ display: 'flex' }}>
                                            <div>
                                                <Button
                                                    className="w3-margin-top"
                                                    color="primary"
                                                    variant="outlined"
                                                    size="medium"
                                                    component="span"
                                                >
                                                    Upload Image
                                                </Button>
                                            </div>
                                        </div>
                                    </label>
                                </Grid>
                                <Grid container item md={12}>
                                    {uploadedFile[field.cardId] && uploadedFile[field.cardId]?.map((image, idx) => (
                                        <Grid item key={idx} style={{ position: 'relative' }}>
                                            <img width="150px" style={{ padding: '5px' }} src={URL.createObjectURL(image)} alt={`Img ${idx}`} />
                                            <IconButton
                                                aria-label="delete"
                                                onClick={() => handleDeleteImage(field.cardId, idx)}
                                                style={{ position: 'absolute', top: '5px', right: '5px'}}
                                            >
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        </Grid>
                                    ))}

                                    {field?.images?.map((image, idx) => {
                                        return <Grid item key={idx} style={{ position: 'relative' }}>
                                            <img width="150px" style={{ padding: '5px' }} src={image?.signedUrl} alt={`Img ${idx}`} />
                                            <IconButton
                                                aria-label="delete"
                                                onClick={() => handleDeleteStoredImage(image)}
                                                style={{ position: 'absolute', top: '5px', right: '5px' }}
                                            >
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        </Grid>
                                    })}
                                </Grid>
                            </Paper>
})}
                        <Grid style={{ display: 'flex', alignItems: 'center' }} item xs={12} sx={{ mt: 2 }} className="font-size-14">
                            <Button
                                variant="outlined"
                                onClick={(event) => {
                                    handleAddField();
                                    event.stopPropagation();
                                }}
                                size="small"
                                className='cursor-pointer'>
                                <AddIcon name="add" />
                                Add Tested Characteristics
                            </Button>

                        </Grid>
                    </Grid>


                    <Grid item xs={12} sx={{ mt: 2 }}>
                        <Grid container className='w3-margin-bottom' spacing={2} justifyItems="space-between">
                            <Grid item md={6} style={{ display: 'flex', justifyContent: 'flex-start', paddingLeft: '16px' }} className='w3-margin-bottom'>
                                {calibration_id && (
                                    <Can do="manage" on="DeleteCalibrationReport">

                                        <Button
                                            variant="outlined"
                                            startIcon={<DeleteIcon />}
                                            color="error"
                                            onClick={() => triggerDelete()}
                                        >
                                            Delete
                                        </Button>
                                    </Can>
                                )}
                            </Grid>
                            <Grid item container md={6} justifyContent="flex-end">
                                <Grid item>
                                    <Button
                                        variant="outlined"
                                        className="w3-margin-right"
                                        onClick={() => navigate(-1)}
                                    >
                                        Cancel
                                    </Button>
                                    <Can do="manage" on="EditCalibrationReport">
                                        <Button
                                            disabled={disableButton}
                                            color="primary"
                                            variant="contained"
                                            onClick={handleSubmit}
                                        >
                                            Submit
                                        </Button>
                                    </Can>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {alertMessage ? (
                <AlertDialog
                    handleClose={() => navigate(-1)}
                    message={alertMessage}
                    type={msgType}
                    handleDialogClose={() => setAlertMessage(null)}
                />) : null}
            {showConfirmDialog
                ? (
                    <ConfirmDialog
                        handleCancel={() => {
                            setSelectedItem({})
                            setShowConfirmDialog(false)
                        }}
                        handleOk={handleDeleteCalibrationReport}
                        message={'Are you sure to delete this report ?'}
                    />
                ) : null}
                {uploadModalMessage ? (
                <AlertDialog
                    handleClose={() => setUploadModalMessage(null)}
                    message={uploadModalMessage}
                    type={msgType}
                    handleDialogClose={() => setUploadModalMessage(null)}
                />) : null}
        </Grid>
    </Paper >
    )
}