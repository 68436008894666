
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDayWiseReport, getProjectById, getProjectMeters, getProjectsFromFacility, getUserProjects } from '../../Redux/actions';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import dayjs from 'dayjs';
import moment from 'moment';
import { Button, Checkbox, CircularProgress, FormControl, Grid, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { isValidDate, dataNotAvailableMsg, generateReportMsg, isGreenUser } from '../../utils/Common';
import EditedConsolidateReports from './EditedConsolidateReports';
import ReportsContent from './ReportsContent';
import DayWiseReport from '../Projects/DayWiseReport';

export default function ReportsView({ dPid, projectIds, facility_id, digiView = false }) {
    const dispatch = useDispatch();
    const { client_id, id } = useParams();
    const state = useSelector(st => st);
    const { userProjectsList, currentUser: { uniqueId } } = state
    const [errors, setErrors] = useState([])
    const [userProjects, setUserProjects] = useState([])
    const [projectData, setProjectData] = useState([])
    const [htmlContent, setHtmlContent] = useState("")
    const [showLoader, setShowLoader] = useState(true);
    const minDate = dayjs().subtract(180, 'days').toDate();
    const maxDate = dayjs().subtract(1, 'days').toDate();
    const reportType = ['Consolidated', 'Day-wise', 'Saved Reports']
    const [selectedReportType, setSelectedReportType] = useState('Consolidated');
    const [contentEditable, setContentEditable] = useState(false);
    const [digiContentEditable, setDigiContentEditable] = useState({});
    const [showReportList, setShowReportList] = useState(false);
    const [digitalisationReports, setdigitalisationReports] = useState([]);
    const [projectIdFromFacility, setProjectIdFromFacility] = useState([])
    const [disableButtons, setDisableButtons] = useState(true)
    const [disableGenerateButton, setDisableGenerateButton] = useState(true)
    const [disableEdit, setDisableEdit] = useState(true)
    const [disableDownload, setDisableDownload] = useState(true)
    const [reportFromDate, setReportFromDate] = useState(
        dayjs().subtract(14, 'days').toDate()
    );
    const [reportToDate, setReportToDate] = useState(maxDate);
    const [archiveReportHtmlContent, setArchiveReportHtmlContent] = useState("")
    const [daywiseReportData, setDaywiseReportData] = useState([]);
    const [selectedMeters, setSelectedMeters] = useState([]);
    const [meterList, setMeterList] = useState([]);
    const [dayWiseReportError, setDayWiseReportError] = useState(false);
    const [consolidateReportError, setConsolidateReportError] = useState(false);
    const [handleClickGenerate, setHandleClickGenerate] = useState(false);
    const [permissionNotFound, setPermissionNotFound] = useState(false)
    const projectId = id ? Number(id) : dPid
    const tableRef = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (facility_id) {
            setPermissionNotFound(false);
            setHtmlContent('');
            setArchiveReportHtmlContent('');
            setConsolidateReportError(false);
            setSelectedReportType("Consolidated");
            dispatch(getProjectsFromFacility(client_id, facility_id)).then(resp => {
                const response = resp?.data?.data
                if (response?.length > 0) {
                    const projectDetails = response?.map((item) => (
                        {
                            pid: item.pid,
                            projectName: item.projname
                        }
                    ))
                    setProjectIdFromFacility(projectDetails)
                }
            })
        }
    }, [facility_id])

    useEffect(() => {
        if (projectIdFromFacility?.length > 0) {
            setdigitalisationReports([]);
            fetchDigiReportData()
        }
    }, [projectIdFromFacility])

    const getProjectMeterData = () => {
        if ((digiView && projectIds?.length === 0) || (!digiView && !projectId)) {
            return
        }
        const params = {
            projectIds: digiView ? projectIds : [projectId]
        }
        dispatch(getProjectMeters(client_id, params)).then(resp => {
            if (!resp.data.success && !resp.data.permissionFound ) {
                setPermissionNotFound(true);
                return
            }
            const respData = resp?.data?.data
            if (respData?.length > 0) {
                setMeterList(respData);
                const meterIds = respData.map(item => item.meterid);
                setSelectedMeters(meterIds)
            }
        });
    }

    useEffect(() => {
        getProjectMeterData();
    }, [projectIds, projectId])

    const fetchDigiReportData = async () => {
        if (selectedReportType === 'Saved Reports') {
            return
        }
        setdigitalisationReports([])
        setShowLoader(true)
        const dgReports = await Promise.all(projectIdFromFacility?.map(async (item) => {
            let reportData;
            if (selectedReportType === 'Consolidated') {
                reportData = await populateReportTableData(item?.pid);
            } else if (selectedReportType === "Day-wise") {
                reportData = await populateDayWiseReportData(item?.pid);
            }
            return {
                projectId: item?.pid,
                reportData,
                projectName: item?.projectName
            };
        }));
        if (dgReports?.length > 0) {
            setDisableDownload(false);
            setDisableEdit(false);
            setdigitalisationReports(dgReports)
        }
    }

    useEffect(() => {
        if (digitalisationReports?.length > 0) {
            setShowLoader(false)
        }
    }, [digitalisationReports])

    useEffect(() => {
        if (projectIdFromFacility?.length > 0) {
            fetchDigiReportData()
        } else {
            fetchReportData()
        }
    }, [contentEditable])

    useEffect(() => {
        setHtmlContent('');
        setdigitalisationReports([]);
        setDaywiseReportData([]);
        setConsolidateReportError(false);
        setShowReportList(false);
        setArchiveReportHtmlContent('');
        setDisableDownload(true);
        setDisableEdit(true);
        setPermissionNotFound(false);
        setErrors({ ...errors, meter: null });
        if (selectedReportType === 'Consolidated') {
            setContentEditable(false);
        }
        if (selectedReportType !== 'Saved Reports') {
            const urlParams = new URLSearchParams(location.search);
            if (urlParams.has('page')) {
                urlParams.delete('page');
                navigate(`${location.pathname}?${urlParams.toString()}`, { replace: true });
            }
        }
        const allMeterIds = meterList.map(meter => meter.meterid);
        setSelectedMeters(allMeterIds);
        if (selectedReportType === 'Saved Reports') {
            setDisableButtons(true);
        }

        const isAnyContentEditable = Object.values(digiContentEditable).some(value => value === true);
        if (isAnyContentEditable) {
            setDigiContentEditable(prev => {
                const updatedEditable = {};
                Object.keys(prev).forEach(pid => {
                    updatedEditable[pid] = false;
                });
                return updatedEditable;
            });
        }
    }, [selectedReportType]);


    const fetchReportData = async () => {
        setHtmlContent('');
        setShowLoader(true)
        try {
            const userProjectsResponse = await dispatch(getUserProjects(client_id));
            if (userProjectsResponse && userProjectsResponse?.data?.success) {
                const projects = userProjectsResponse?.data?.data;
                if (projects?.length > 0) {
                    setUserProjects(projects);
                    setShowReportList(false);
                    if (!digiView) {
                        if (selectedReportType === 'Consolidated') {
                            populateReportTableData(projectId);
                        } else if (selectedReportType === 'Day-wise') {
                            populateDayWiseReportData(projectId);
                        } else if (selectedReportType === "Saved Reports") {
                            setShowReportList(true);
                            setDisableDownload(true);
                        }
                    }
                }
            }
            if (!digiView) {
                const projectResponse = await dispatch(getProjectById(client_id, projectId));
                if (projectResponse?.data?.success && projectResponse?.data?.projectData) {
                    setProjectData(projectResponse.data.projectData);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchReportData();
    }, [1])

    useEffect(() => {
        const divElements = document.getElementsByClassName('trigger-pdf-save');
        if (divElements?.length > 0) {
            for (const divElement of divElements) {
                divElement.addEventListener('click', handleSaveReport);
            }

            return () => {
                for (const divElement of divElements) {
                    divElement.removeEventListener('click', handleSaveReport);
                }
            };
        }

        if (htmlContent && !digiView) {
            setShowLoader(false)
        }
        return () => {
            if (selectedReportType !== 'Consolidated') {
                setDigiContentEditable({})
                setContentEditable(false)
            }
        }
    }, [htmlContent]);

    useEffect(() => {
        if (showLoader) {
            setDisableButtons(true)
            setDisableGenerateButton(true)
        } else {
            if (selectedReportType !== 'Saved Reports') {
                setDisableButtons(false)
            }
            if ((selectedReportType === 'Day-wise' && selectedMeters?.length > 0)) {
                setDisableGenerateButton(false)
            } else if (selectedReportType !== 'Day-wise') {
                setDisableGenerateButton(false)
            }
        }
    }, [showLoader, showReportList])

    const handleSaveReport = () => {
        const css = '@page { size: landscape; }';
        const head = document.head || document.getElementsByTagName('head')[0];
        const style = document.createElement('style');
        style.media = 'print';
        if (style.styleSheet) {
            style.styleSheet.cssText = css;
        } else {
            style.appendChild(document.createTextNode(css));
        }
        head.appendChild(style);
        window.print();
    }

    const validateForm = () => {
        let formIsValid = true;
        let err = { ...errors };

        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const fromDate = reportFromDate ? new Date(reportFromDate) : null;
        if (fromDate) { fromDate.setHours(0, 0, 0, 0); }

        const toDate = reportToDate ? new Date(reportToDate) : null;
        if (toDate) { toDate.setHours(0, 0, 0, 0); }

        if (!reportFromDate) {
            formIsValid = false;
            err.reportFromDate = 'Please select report From Date';
        }

        if (!reportToDate) {
            formIsValid = false;
            err.reportToDate = 'Please select report To Date';
        }

        if (moment(reportFromDate).format('YYYY-MM-DD') > moment(reportToDate).format('YYYY-MM-DD')) {
            formIsValid = false;
            err.reportFromDate = 'From Date should be less than To Date';
        }

        if (!isValidDate(reportFromDate)) {
            formIsValid = false;
            err.reportFromDate = 'Please enter a valid date in the format DD/MM/YYYY';
        }

        if (!isValidDate(reportToDate)) {
            formIsValid = false;
            err.reportToDate = 'Please enter a valid date in the format DD/MM/YYYY';
        }

        if (fromDate >= today) {
            formIsValid = false;
            err.reportFromDate = "From Date should be less than today's Date";
        }

        if (toDate >= today) {
            formIsValid = false;
            err.reportToDate = "To Date should be less than today's Date";
        }

        if (!selectedReportType) {
            formIsValid = false;
            err.selectedReportType = 'Please select a type';
        }
        if (selectedReportType === 'Day-wise' && selectedMeters.length === 0) {
            formIsValid = false;
            err.meter = 'Please select meter';
        }

        setErrors(err);
        return formIsValid;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setHtmlContent('');
        const valid = validateForm();
        if (!valid) {
            return;
        }
        setHandleClickGenerate(!handleClickGenerate);
        setDisableButtons(true);
        setDisableDownload(true);
        setDisableEdit(true);
        setDisableGenerateButton(true)
        setdigitalisationReports([])
        setShowReportList(false)

        if (!digiView) {
            setShowReportList(false)
            setShowLoader(true)
            if (selectedReportType === 'Consolidated') {
                populateReportTableData(projectId);
            } else if (selectedReportType === 'Day-wise') {
                populateDayWiseReportData(projectId)
            } else if (selectedReportType === "Saved Reports") {
                setShowReportList(true)
                setShowLoader(false)
            }
        } else {
            setdigitalisationReports([])
            fetchDigiReportData()
            if (selectedReportType === "Saved Reports") {
                setDisableDownload(true);
                setShowReportList(true)
            }
        }
        if ((selectedReportType === 'Day-wise' && selectedMeters?.length > 0)) {
            setDisableGenerateButton(false)
        } else if (selectedReportType !== 'Day-wise') {
            setDisableGenerateButton(false)
        }
    }

    const populateDayWiseReportData = async (reportProjectId) => {
        try {
            const params = {
                fromDate: moment(reportFromDate).format('YYYY-MM-DD'),
                toDate: moment(reportToDate).format('YYYY-MM-DD'),
                clientId: client_id,
                selectedMeters: selectedMeters
            }
            const dayWiseData = await dispatch(getDayWiseReport(reportProjectId, params)).then((res) => {
                if ( !res.data.permissionFound && (res.data.permissionFound !== undefined) ) {
                    setPermissionNotFound(true);
                    setShowLoader(false);
                    return
                }else if( !res.data.success ) {
                    setDayWiseReportError(true);
                    setShowLoader(false);
                    setDisableGenerateButton(false)
                    return
                } 
                const response = res?.data?.data;
                if (response) {
                    if (digiView) {
                        return response
                    }
                    setDaywiseReportData(response);
                    setShowLoader(false);
                    setDisableDownload(false);
                }
            });
            if (digiView) {
                return dayWiseData
            }
        } catch (error) {
            setDayWiseReportError(true);
            console.error('Error fetching day wise data:', error);
        }
    }

    const populateReportTableData = async (reportProjectId) => {
        const params = {
            'pid': reportProjectId,
            'report_from_date': moment(reportFromDate).format('YYYY-MM-DD'),
            'report_to_date': moment(reportToDate).format('YYYY-MM-DD'),
            'client_id': client_id,
            'content_editable': contentEditable
        }

        try {
            const response = await axios.get(`/papi/v1/populate-report-table/`, { params });
            if (response?.data) {
                if (digiView) {
                    return response.data
                }
                setHtmlContent(response.data)
                setDisableDownload(false);
                setDisableEdit(false)
            } else {
                setConsolidateReportError(true);
            }
        } catch (error) {
            setConsolidateReportError(true);
            console.error('Error fetching data:', error);
        } finally {
            if (!digiView) {
                setShowLoader(false)
                if (selectedReportType === 'Consolidated') {
                    setDisableEdit(false)
                }
            }
        }
    }

    const handleShowReportContent = () => {
        if (showLoader) {
            return (
                <div className="w3-center" style={{ marginTop: 30 }}>
                    <CircularProgress />
                </div>
            );
        }

        const renderDayWiseReport = (item, index) => (
            <DayWiseReport
                key={item?.projectId || index}
                pid={item?.projectId || projectId}
                client_id={client_id}
                digiView={digiView}
                fromDate={reportFromDate}
                toDate={reportToDate}
                projectName={item?.projectName || projectData?.projname}
                daywiseReportData={item.reportData || daywiseReportData}
                dayWiseReportError={dayWiseReportError}
            />
        );

        const renderReportsContent = (item, index) => {
            if (consolidateReportError) {
                return dataNotAvailableMsg
            }
            if ((!digiView && !htmlContent) || (digiView && !item?.reportData)) {
                if (digiView && index > 0) { return }
                return <div className='w3-center' style={{ margin: '44px 0 20px 0', fontSize: 16 }}>Please click on generate report to view the report</div>
            }
            return <>
                <ReportsContent
                    key={item?.projectId || index}
                    digiView={digiView}
                    projectIds={projectIds || dPid}
                    htmlDataContent={item?.reportData || htmlContent}
                    editReport={digiContentEditable?.[item?.projectId] || contentEditable}
                    reportFromDate={reportFromDate}
                    reportToDate={reportToDate}
                    projectData={projectData}
                    client_id={client_id}
                    projectId={item?.projectId || projectId}
                    reportType={selectedReportType}
                    onReportSaved={handleReportSaved}
                    projectName={item?.projectName || projectData?.projname}
                    tableRef={tableRef}
                />
                {index !== (projectIds?.length - 1) && digiView && <div className="hide-in-print" style={{ marginTop: '20px' }}><hr /></div>}
            </>
        };

        const renderEditedConsolidateReports = () => (
            <EditedConsolidateReports
                // key={item?.projectId}
                pid={projectIds || projectId}
                setDisableDownload={setDisableDownload}
                archiveReportHtmlContent={archiveReportHtmlContent}
                setArchiveReportHtmlContent={setArchiveReportHtmlContent}
                reportType={selectedReportType}
                onReportSaved={handleReportSaved}
                projectName={projectData?.projname}
                handleClickGenerate={handleClickGenerate}
                setPermissionNotFound={setPermissionNotFound}
            />
        );

        if (selectedReportType === 'Day-wise') {
            if (dayWiseReportError) {
                return dataNotAvailableMsg
            } else if ((digiView && digitalisationReports?.length === 0) || (!digiView && daywiseReportData.length === 0)) {
                return generateReportMsg;
            } else if (digiView && digitalisationReports?.length > 0) {
                return digitalisationReports.map(renderDayWiseReport)
            } else if (!digiView) {
                return renderDayWiseReport({});
            }
        } else if (selectedReportType === 'Consolidated' || selectedReportType === "Saved Reports") {
            if (showReportList) {
                return renderEditedConsolidateReports({});
            }
            if ((digiView && digitalisationReports?.length === 0)) {
                return generateReportMsg;
            } else if (digiView && digitalisationReports?.length > 0) {
                return digitalisationReports.map(renderReportsContent)
            }
            return renderReportsContent({});
        }
    }

    const handleReportSaved = (pid) => {
        setContentEditable(false)
        setDigiContentEditable(prev => ({
            ...prev,
            [pid]: false
        }));
    }

    const handleChange = (event) => {
        const { target: { value } } = event;
        setErrors({ ...errors, meter: null });
        if (value.includes("Select All")) {
            if (selectedMeters.length === meterList.length) {
                setSelectedMeters([]);
            } else {
                const allMeterIds = meterList.map(meter => meter.meterid);
                setSelectedMeters(allMeterIds);
            }
        } else {
            const newValue = typeof value === 'string' ? value.split(',') : value;
            const filteredValue = newValue.filter(item => item !== "Select All");
            setSelectedMeters(filteredValue);
        }
    }

    if (userProjectsList?.isFetching && (userProjects?.length === 0)) {
        return <div className="w3-center" style={{ marginTop: 30 }}>
            <CircularProgress />
        </div>
    } else if (permissionNotFound) {
        return <Grid container >
            <Grid item md={12} className='w3-padding' style={{ display: 'flex', justifyContent: 'center', height: '500px', alignItems: 'center' }}>
                <h5 className='w3-text-grey'>
                    You don't have view or manage permission
                </h5>
            </Grid>
        </Grid>
    }

    return (
        <div className="main w3-padding-large" style={{ marginTop: 20 }}>
                <div id="report-container" className='report-view'>
                    <div className="reports-header hide-in-print">
                        <div className="reports-header hide-in-print">
                            <Grid container spacing={2} alignItems="flex-start">
                                <Grid item xs={12} sm={3} md={3}>
                                    <FormControl fullWidth size="small" error={!!errors.selectedReportType}>
                                        <InputLabel id="reportType-label">Report Type*</InputLabel>
                                        <Select
                                            labelId="reportType-label"
                                            id="reportType"
                                            MenuProps={{ disableScrollLock: true }}
                                            value={selectedReportType}
                                            style={{ height: '38px', width: '100%' }}
                                            onChange={(e) => {
                                                setdigitalisationReports([]);
                                                setSelectedReportType(e.target.value);
                                                setContentEditable(false);
                                                setShowReportList(false);
                                                setArchiveReportHtmlContent("");
                                                if (selectedReportType === "Saved Reports") {
                                                    setDisableButtons(false);
                                                }
                                            }}
                                            input={<OutlinedInput label="Report Type" />}
                                        >
                                            {reportType?.map((rType, idx) => (
                                                <MenuItem key={`${rType}_${idx}`} value={rType}>
                                                    <ListItemText primary={rType} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {errors.selectedReportType && <div className="error-text">{errors.selectedReportType}</div>}
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={3} md={3}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="From Date *"
                                            format="DD/MM/YYYY"
                                            required
                                            fullWidth
                                            disabled={disableButtons}
                                            value={dayjs(reportFromDate)}
                                            maxDate={dayjs(maxDate)}
                                            onChange={(newValue) => {
                                                setReportFromDate(dayjs(newValue).toDate());
                                                setErrors({ ...errors, reportFromDate: null, reportToDate: null });
                                                if (selectedReportType === "Consolidated" && contentEditable) {
                                                    setContentEditable(false);
                                                }
                                            }}
                                            slotProps={{ textField: { fullWidth: true, size: 'small', error: !!errors.reportFromDate } }}
                                        />
                                    </LocalizationProvider>
                                    {errors.reportFromDate && <div className="error-text">{errors.reportFromDate}</div>}
                                </Grid>

                                <Grid item xs={12} sm={3} md={3}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="To Date *"
                                            format="DD/MM/YYYY"
                                            required
                                            fullWidth
                                            disabled={disableButtons}
                                            value={dayjs(reportToDate)}
                                            minDate={dayjs(reportFromDate) || dayjs(minDate)}
                                            maxDate={dayjs(maxDate)}
                                            onChange={(newValue) => {
                                                setReportToDate(dayjs(newValue).toDate());
                                                setErrors({ ...errors, reportToDate: null, reportFromDate: null });
                                                if (selectedReportType === "Consolidated" && contentEditable) {
                                                    setContentEditable(false);
                                                }
                                            }}
                                            slotProps={{ textField: { fullWidth: true, size: 'small', error: !!errors.reportToDate } }}
                                        />
                                    </LocalizationProvider>
                                    {errors.reportToDate && <div className="error-text">{errors.reportToDate}</div>}
                                </Grid>

                                <Grid item xs={12} sm={3} md={3}>
                                    <FormControl disabled={selectedReportType !== "Day-wise"} fullWidth size="small" error={!!errors.meter}>
                                        <InputLabel id="meters-label">Meters*</InputLabel>
                                        <Select
                                            labelId="meters-label"
                                            id="meterId"
                                            multiple
                                            MenuProps={{
                                                autoFocus: false,
                                                disableScrollLock: true,
                                                anchorOrigin: { vertical: "bottom", horizontal: "left" },
                                                transformOrigin: { vertical: "top", horizontal: "left" },
                                                getContentAnchorEl: null,
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: 350,
                                                        overflowY: "auto",
                                                        width: '100%',
                                                        maxWidth: 260
                                                    },
                                                },
                                            }}
                                            value={selectedMeters}
                                            onChange={handleChange}
                                            input={<OutlinedInput label="Meters" />}
                                            renderValue={(selected) => selected.length === meterList.length ? 'All Meters' : `${selected.length} selected`}
                                        >
                                            {meterList?.length > 0 && (
                                                <MenuItem style={{wordWrap: 'break-word', whiteSpace: 'normal'}} value="Select All">
                                                    <Checkbox checked={selectedMeters.length === meterList.length} indeterminate={selectedMeters.length > 0 && selectedMeters.length < meterList.length} />
                                                    <ListItemText primary="Select All" />
                                                </MenuItem>
                                            )}
                                            {meterList.map((meter, idx) => (
                                                <MenuItem style={{wordWrap: 'break-word', whiteSpace: 'normal'}} key={`${meter.meterid}-${idx}`} value={meter.meterid}>
                                                    <Checkbox checked={selectedMeters.includes(meter.meterid)} />
                                                    <ListItemText primary={`${meter.metername} (${meter.meterid})`} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {errors.meter && <div className="error-text">{errors.meter}</div>}
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid container
                                justifyContent="center"
                                spacing={2} style={{ marginTop: '8px' }}>
                                <Grid item>
                                    <Button
                                        style={{ padding: 7, width: '100%' }}
                                        fullWidth size="small" color="primary" variant="contained" onClick={handleSubmit} disabled={disableGenerateButton}>
                                        Generate Report
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        style={{ color: disableDownload || disableGenerateButton ? 'gray' : '#1976d2' }}
                                        onClick={handleSaveReport}
                                        disabled={disableDownload || disableGenerateButton}
                                        variant="outlined"
                                    >
                                        Download
                                    </Button>
                                </Grid>
                                {selectedReportType === 'Consolidated' && isGreenUser(uniqueId) && (
                                    <Grid item>
                                        <Button
                                            style={{ color: disableEdit || disableGenerateButton ? 'gray' : '#1976d2' }}
                                            onClick={() => {
                                                setContentEditable(!contentEditable);
                                                // setDisableEdit(true);
                                            }}
                                            disabled={disableEdit || disableGenerateButton}
                                            variant="outlined"
                                        >
                                            {contentEditable ? 'Cancel Edit' : 'Edit'}
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>

                        </div>

                    </div>

                    {handleShowReportContent()}
                </div>
        </div >
    )
}